import { createSelector } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { ApiStatus } from "./api.enhanced";
const deepEqual = require("deep-equal");

/**
 * Memoize the results of the function to ensure that re-renders don't happen if the result is the same.
 * Also logs out any API query failures to the console.
 * See https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#selecting-values-from-results for further information
 *
 * @param fn the function to run
 * @param name the name of the function, for error logging
 * @returns the memoized function
 */
export const useMemoSelector = <R, T>(
	fn: (data: T, apiStatus: ApiStatus) => R,
	name: string
) => {
	return useMemo(() => {
		// Return a unique selector instance so that the data is correctly memoized
		return createSelector(
			[
				(data: T, _: ApiStatus) => data,
				(_: T, apiStatus: ApiStatus) => apiStatus,
			],
			(data: T, apiStatus: ApiStatus) => {
				if (apiStatus.isError) {
					console.log(
						`API call failure for ${name}, got: ${JSON.stringify(
							data
						)}`
					);
				}
				return fn(data, apiStatus);
			},
			{
				memoizeOptions: {
					// Ensure it uses deep equality to compare
					resultEqualityCheck: deepEqual,
				},
			}
		);
	}, [fn, name]);
};
