import humanizeDuration from "humanize-duration";

/**
 * A function that makes a duration more human readable
 */
export const durationUtil = humanizeDuration.humanizer({
	language: "shortEn",
	units: ["y", "mo", "d", "h", "m", "s"],
	delimiter: ":",
	spacer: "",
	largest: 2,
	languages: {
		shortEn: {
			y: () => "Y",
			mo: () => "MO",
			d: () => "D",
			h: () => "H",
			m: () => "M",
			s: () => "S",
		},
	},
});

/**
 * Calculates the time ago for a given iso8601 string and returns it in a human readable format
 *
 * @param iso8601 the timestamp in iso8601 format
 * @returns how long ago the timestamp was in a human readable format
 */
export const timeAgo = (iso8601: string) => {
	const msAgo = Date.now() - Date.parse(iso8601);
	return humanizeDuration(msAgo, {
		delimiter: " and ",
		largest: 2,
		round: true,
	});
};
