import {
	Card,
	CardContent,
	Divider,
	Typography,
	Box,
	CardActions,
} from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";

import "./engine-tile.scss";
import { useGetEngine, useGetUptime } from "../../../api/engine";
import EngineTileEvents from "./engine-tile-events";
import EngineEventsList from "./engine-events-list";
import EngineTags from "./engine-tags/engine-tags";
import EngineStats from "./engine-stats";
import EngineUptime from "./engine-uptime";
import ErrorQueue from "./error-queue";
import { timeAgo } from "../../../utils/duration-util";
import EngineCardHeader from "./engine-card-header";
import Throughput from "./throughput";
import AlertMessage from "../../../components/alert-banners/alert-message";
import { AlertType } from "../../../components/alert-banners/alert-banner";
import {
	ErrorBoundary,
	FallbackType,
} from "../../../components/error-handling/error-boundary";
import { memo, useState } from "react";

const UPTIME_EXPIRY_SECONDS = 60 * 5;
const IsEngineConnected = (lastUpdated: string, currentDate: number) => {
	const timeInMs = 1000 * UPTIME_EXPIRY_SECONDS;
	return !!(lastUpdated && Date.parse(lastUpdated) > currentDate - timeInMs);
};

type EngineTileProps = {
	id: string;
};

/**
 * @param param0 the parameters as EngineTileProps
 * @returns an engine tile
 */
const EngineTile = ({ id }: EngineTileProps) => {
	const EngineCardHeaderMemo = memo(EngineCardHeader);

	const [selectedSeverity, setSelectedSeverity] = useState<string[]>([]);
	const handleEventSelect = (
		_: React.MouseEvent<HTMLElement>,
		newSeverity: string[]
	) => {
		setSelectedSeverity(newSeverity);
	};

	const { name, link, isEngineError, engineError } = useGetEngine(
		id,
		(data, apiStatus) => {
			if (apiStatus.isSuccess && data) {
				return {
					name: data.name ?? "NO NAME",
					link: data.link,
					isEngineError: apiStatus.isError,
					engineError: null,
				};
			}
			return {
				name: "",
				link: "",
				isEngineError: apiStatus.isError,
				engineError: apiStatus.error,
			};
		}
	);

	const { isEngineConnected, lastUpdated, isUptimeError, uptimeError } =
		useGetUptime(id, (data, apiStatus) => {
			if (apiStatus.isSuccess && data) {
				const uptimeValue = data?.lastUpdate ?? "";

				const isEngineConnected = IsEngineConnected(
					uptimeValue,
					data?.currentTime ?? 0
				);

				// We only care about the last updated time for display when its offline, prevent unnecessary re-renders
				const lastUpdated =
					isEngineConnected || !uptimeValue
						? ""
						: timeAgo(uptimeValue);
				return {
					isEngineConnected,
					lastUpdated,
					isUptimeError: apiStatus.isError,
					uptimeError: null,
				};
			}
			return {
				isEngineConnected: false,
				lastUpdated: "",
				isUptimeError: apiStatus.isError,
				uptimeError: apiStatus.error,
			};
		});

	if (isEngineError) {
		return (
			<div data-testid="engine-not-success">
				<AlertMessage
					alertType={AlertType.Error}
					message={JSON.stringify(engineError)}
				/>
			</div>
		);
	}
	if (isUptimeError) {
		return (
			<div data-testid="uptime-not-success">
				<AlertMessage
					alertType={AlertType.Error}
					message={JSON.stringify(uptimeError)}
				/>
			</div>
		);
	}

	return (
		<Box
			component={Card}
			className={`card ${isEngineConnected ? "" : "not-connected"}`}
			data-testid={"card-" + id}
			display="flex"
			flexDirection="column">
			<EngineCardHeaderMemo
				id={id}
				name={name}
				link={link}
				isConnected={isEngineConnected}
			/>
			<Divider />
			<Box flexGrow={1} component={CardContent}>
				{isEngineConnected ? (
					<>
						<Box className="throughput">
							<ErrorBoundary
								scope={"Loading throughput"}
								fallback={FallbackType.AlertMessage}
								message={`Error loading engine throughput for '${id}'`}
								styles={{ marginTop: "1em", padding: 0 }}>
								<Throughput id={id} />
							</ErrorBoundary>
						</Box>
						<ErrorBoundary
							scope={"Loading engine stats"}
							fallback={FallbackType.AlertMessage}
							message={`Error loading engine stats for '${id}'`}
							styles={{ marginTop: "1em", padding: 0 }}>
							<EngineStats id={id} />
						</ErrorBoundary>
						<Box
							display="flex"
							alignItems="center"
							className="content-footer">
							<EngineUptime id={id} />
							<Box flexGrow="1" />
							<ErrorQueue id={id} link={link} />
							<EngineTileEvents
								id={id}
								severity={selectedSeverity}
								handleSelect={handleEventSelect}
							/>
						</Box>
						<EngineEventsList
							id={id}
							link={link}
							selectedSeverity={selectedSeverity}
						/>
					</>
				) : (
					<Box display="flex" alignItems="center" flexDirection="row">
						<ErrorIcon fontSize="large" />
						<div className="not-connected-message">
							<Typography variant="h3" color="textPrimary">
								Not connected.
							</Typography>
							<>
								{lastUpdated === "" ? (
									<Typography
										variant="h5"
										color="textSecondary"
										data-testid="not-connected-never">
										Never received data
									</Typography>
								) : (
									<Typography
										variant="h5"
										color="textSecondary"
										data-testid="not-connected">
										Last update ~{lastUpdated} ago.
									</Typography>
								)}
							</>
						</div>
					</Box>
				)}
			</Box>
			<CardActions className="card-actions">
				<EngineTags engine={id} />
			</CardActions>
		</Box>
	);
};

export default EngineTile;
