import { Autocomplete, AutocompleteRenderInputParams, TextField } from "@mui/material";
import { SortByType } from "./sort-by-type";

/**
 * Properties of the SortBy component 
 */
type SortByProps = {
	sortByType: SortByType;
	setSortByType: (result: string) => void;
};

const renderInputCallback = (params: AutocompleteRenderInputParams) => {
	return (<TextField variant="outlined" {...params} label="Sort by" />)
}

/**
 * @param param0 the sort by input option type
 * @returns the selected sort by option type
 */
const SortBy = ({ sortByType, setSortByType }: SortByProps) => {
	const sortOptions = [SortByType.Name, SortByType.Severity];

	const onChangeCallback = (_: React.SyntheticEvent<Element, Event>, value: string) => {
		setSortByType(value);
	}

	return (
		<Autocomplete
			options={sortOptions}
			disableClearable
			data-testid="sort-by"
			value={sortByType}
			renderInput={renderInputCallback}
			onChange={onChangeCallback}
		/>
	);
};

export default SortBy;
