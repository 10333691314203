/* eslint-disable @typescript-eslint/no-unused-vars */
const isProd = process.env.REACT_APP_DEPLOY_ENV === "prod";
const isStaging = process.env.REACT_APP_DEPLOY_ENV === "staging";
const isDev = process.env.REACT_APP_DEPLOY_ENV === "dev";
const isLocal = process.env.NODE_ENV === "development";

/**
 * The feature flag for whether we display the rhapsody menu.
 * Within this menu is the component-list element and children.
 */
export const IsRhapsodyEnabled = isDev || isLocal;
