// Adapted from https://observablehq.com/@d3/zoomable-sunburst, with some help from https://dev.to/andrewchmr/react-d3-sunburst-chart-3cpd
import { useEffect, useState } from "react";
import * as d3 from "d3";
import { TreeComponent } from "./component-list";
import useDeepCompareEffect from "use-deep-compare-effect";

// The width of the entire svg in pixels.
const width = 932;
// The radius of each segment in pixels. Since we have 3 levels (center + 2), we divide the width by 6.
const radius = width / 6;
// The transition duration of zooming in milliseconds.
const transitionDuration = 1000;
// The number of radians in a circle
const circleRadians = Math.PI * 2;

// The properties for the control
type SunburstProps = {
	data: TreeComponent[]; // The data to display
	setSelected: (t: TreeComponent) => void; // The function to fire on a selection
};

// Helper functions to get relative positions based on the current node
type ZoomDetails = {
	// Subtract the theta of the center node so it starts at the top of the circle. Then divides it by the theta of the current center node to get a relative size in radians.
	getX: (x: number) => number;
	// Subtracts the depth.
	getY: (y: number) => number;
};

// Create a d3 partition
const partition = (data: TreeComponent) => {
	// Create a hierarchy, sorting by most children
	const root = d3
		.hierarchy(data)
		.sum((d) => d.value)
		.sort((a, b) => (b.value ?? 0) - (a.value ?? 0));
	// Partition in a polar coordinate system using radians.
	// This results in x0 being theta for the start of the arc and x1 being the theta for the end of the arc.
	// The y axis is the children (the r in the polar coordinates).
	return d3.partition<TreeComponent>().size([circleRadians, root.height + 1])(
		root
	);
};

// Create an arc generator. Args are ZoomDetails.
const arc = d3
	.arc<d3.HierarchyRectangularNode<TreeComponent>>()
	// Thetas for the start and end of the arc in radians. Divide it by the ratio so it is relative.
	.startAngle((d, zoom: ZoomDetails) => zoom.getX(d.x0))
	.endAngle((d, zoom: ZoomDetails) => zoom.getX(d.x1))
	// Add some padding to separate the items
	.padAngle((d, zoom: ZoomDetails) =>
		Math.min((zoom.getX(d.x1) - zoom.getX(d.x0)) / 2, 0.005)
	)
	.padRadius(radius * 1.5)
	// Set up the thickness of each level
	.innerRadius((d, zoom: ZoomDetails) => zoom.getY(d.y0) * radius)
	.outerRadius((d, zoom: ZoomDetails) =>
		Math.max(zoom.getY(d.y0) * radius, zoom.getY(d.y1) * radius - 1)
	);

// Determine whether a label is visible.
const labelVisible = (
	d: d3.HierarchyRectangularNode<TreeComponent>,
	zoom: ZoomDetails
) => {
	const x0 = zoom.getX(d.x0);
	const x1 = zoom.getX(d.x1);
	const y0 = zoom.getY(d.y0);
	const y1 = zoom.getY(d.y1);
	return (
		// Don't display if its above the number of levels.
		y1 <= 3 &&
		// Don't display the root one, we do that ourselves.
		y0 >= 1 &&
		// Don't display it if the size would be too small
		(y1 - y0) * (x1 - x0) > 0.03
	);
};

// Converts radians to degrees
const radiansToDegrees = (rad: number) => (rad * 180) / Math.PI;

// Transform the label for display
const labelTransform = (
	d: d3.HierarchyRectangularNode<TreeComponent>,
	zoom: ZoomDetails
) => {
	const x0 = zoom.getX(d.x0);
	const x1 = zoom.getX(d.x1);
	const y0 = zoom.getY(d.y0);
	const y1 = zoom.getY(d.y1);
	// Rotate it so we display it on each arc
	const x = radiansToDegrees((x0 + x1) / 2);
	const y = ((y0 + y1) / 2) * radius;
	return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
};

// Determine whether an arc is visible
const arcVisible = (
	d: d3.HierarchyRectangularNode<TreeComponent>,
	zoom: ZoomDetails
) =>
	// Don't display if its above the number of levels.
	zoom.getY(d.y1) <= 3 &&
	// Don't display the root one, we do that ourselves.
	zoom.getY(d.y0) >= 1 &&
	// Don't display it if the size would be too small
	zoom.getX(d.x1) > zoom.getX(d.x0);

// Helper function to determine a node colour
const colour = (d: d3.HierarchyRectangularNode<TreeComponent>) => {
	// If its red or any children are red, return red
	if (
		d.data.colour === "red" ||
		d.children?.find((d) => colour(d) === "red")
	) {
		return "red";
	}
	return "green";
};

// An arc that is displayed over top of the sunburst during zooming to provide an animation
const animationArc = d3
	.arc<number>()
	.startAngle(0)
	.endAngle((percent) => circleRadians * percent) // Times by percent to limit what is displayed.
	.innerRadius(0)
	.outerRadius(width); // Ensure this is large enough to cover any layers that are temporarily visible

// Get the zoom details of the node
const getZoomDetails = (
	centerNode?: d3.HierarchyRectangularNode<TreeComponent>
): ZoomDetails => {
	// The start theta of this nodes arc
	const theta0 = centerNode?.x0 ?? 0;
	// How much of a circle this arcs theta is
	const ratio = centerNode
		? (centerNode.x1 - centerNode.x0) / circleRadians
		: 1;
	// What depth it is
	const depth = centerNode?.depth ?? 0;

	return {
		getX: (x: number) => (x - theta0) / ratio,
		getY: (y: number) => y - depth,
	};
};

/**
 * WIP: behind IsRhapsodyEnabled feature flag
 *
 * @param param0 the parameters as SunburstProps
 * @returns a sunburst
 */
const SunburstChart = ({ data, setSelected }: SunburstProps) => {
	// The labels to display
	const [labels, setLabels] = useState<
		d3.HierarchyRectangularNode<TreeComponent>[]
	>([]);
	// The arcs to display
	const [arcs, setArcs] = useState<
		d3.HierarchyRectangularNode<TreeComponent>[]
	>([]);
	// The current center node
	const [centerNode, setCenterNode] =
		useState<d3.HierarchyRectangularNode<TreeComponent>>();
	// The current state of the animation, how much of the arc is visible
	const [percentVisible, setPercentVisible] = useState(0);

	// Only re-render when the data has changed
	useDeepCompareEffect(() => {
		if (data.length === 0) {
			return;
		}

		// Generate the partition
		const root = partition(data[0]);
		// Set the center node, triggering the other useEffect
		setCenterNode(root);
	}, [data]);

	useEffect(() => {
		if (centerNode) {
			// Mark the animation arc as fully visible
			setPercentVisible(1);
			// Set it as selected
			setSelected(centerNode.data);
			// Work out the zoom details
			const zoom = getZoomDetails(centerNode);
			// Create the arcs
			setArcs(
				centerNode
					.descendants()
					.slice(1)
					.filter((d) => arcVisible(d, zoom))
			);
			// Create the labels
			setLabels(
				centerNode
					.descendants()
					.slice(1)
					.filter((d) => labelVisible(d, zoom))
			);
			// Perform the visibility transition
			d3.transition()
				.duration(transitionDuration)
				.tween("dive-tween", () => {
					const percentInterpolate = d3.interpolate(1, 0);
					return (t: number) =>
						setPercentVisible(percentInterpolate(t));
				});
		}
	}, [centerNode, setSelected]);

	// On clicking a node or the center
	const clicked = (p: d3.HierarchyRectangularNode<TreeComponent>) => {
		// Mark the animation arc as fully visible
		setPercentVisible(1);
		// Update the center node triggering the useEffect
		setCenterNode(p);
	};

	const zoom = getZoomDetails(centerNode);
	return (
		<svg
			width={width}
			height={width}
			viewBox={`0,0,${width},${width}`}
			fontSize="10px"
		>
			<g transform={`translate(${width / 2},${width / 2})`}>
				{/* Render all the arcs */}
				{arcs.map((d, i) => (
					<path
						key={`arc-${i}`}
						fill={colour(d)}
						fillOpacity={d.children ? 0.6 : 0.4}
						pointerEvents="auto"
						d={arc(d, zoom) ?? undefined}
						cursor={d.children ? "pointer" : undefined}
						onClick={() => {
							if (d.children) {
								clicked(d);
							}
						}}
					/>
				))}
				{/* Render all the labels */}
				<g
					pointerEvents="none"
					textAnchor="middle"
					style={{ userSelect: "none" }}
				>
					{labels.map((d, i) => (
						<text
							key={`text-${i}`}
							dy="0.35em"
							fillOpacity={1}
							transform={labelTransform(d, zoom)}
						>
							{d.data.name}
						</text>
					))}
				</g>
				{/* Render the animation */}
				<path
					d={animationArc(-percentVisible) ?? undefined}
					fill="#d9dfe6"
				/>
				{/* Render the center node */}
				{centerNode && (
					<>
						<circle
							r={radius}
							fill="none"
							pointerEvents="all"
							onClick={() => {
								// Set as if we clicked the parent so we zoom up a level
								if (centerNode?.parent) {
									clicked(centerNode.parent);
								}
							}}
						/>
						<text
							pointerEvents="none"
							textAnchor="middle"
							style={{ userSelect: "none" }}
						>
							{centerNode?.data.name ?? ""}
						</text>
					</>
				)}
			</g>
		</svg>
	);
};

export default SunburstChart;
