import "chart.js/auto";
import "chartjs-adapter-moment";
import { ChartData, ChartOptions } from "chart.js";
import { Line } from "react-chartjs-2";
import { Skeleton } from "@mui/material";

/**
 * The properties of a given line chart
 */
export type LineDataProps = {
	lineDataProcessed: {
		x: number;
		y: number;
	}[];
	lineDataSent: {
		x: number;
		y: number;
	}[];
	lineDataReceived: {
		x: number;
		y: number;
	}[];
	lineDataFailed: {
		x: number;
		y: number;
	}[];
	minUnit: TimeUnit;
	isLoading: boolean;
};

/**
 * The time unit of the line chart
 */
export type TimeUnit = "hour" | "day";

const getLabelFormats = (minUnit: TimeUnit) => {
	if (minUnit === "hour") {
		return {
			minute: "h:mm a",
			hour: "h:mm a",
		};
	} else {
		return {
			hour: "MMM DD",
			day: "MMM DD",
		};
	}
};

/**
 * @param param0 the parameters as LineDataProps
 * @returns a line chart
 */
export const ChartjsLineChart = ({
	lineDataProcessed,
	lineDataReceived,
	lineDataSent,
	lineDataFailed,
	minUnit,
	isLoading,
}: LineDataProps) => {
	const data: ChartData<"line"> = {
		datasets: [
			{
				borderColor: "#36a2eb",
				backgroundColor: "#36a2eb",
				borderWidth: 1,
				data: lineDataProcessed,
				label: "Processed",
				pointRadius: 0,
			},
			{
				borderColor: "#4bc0c0",
				backgroundColor: "#4bc0c0",
				borderWidth: 1,
				data: lineDataReceived,
				label: "Received",
				pointRadius: 0,
			},
			{
				borderColor: "#daad86",
				backgroundColor: "#daad86",
				borderWidth: 1,
				data: lineDataSent,
				label: "Sent",
				pointRadius: 0,
			},
			{
				borderColor: "#ff6384",
				backgroundColor: "#ff6384",
				borderWidth: 1,
				data: lineDataFailed,
				label: "Failed",
				pointRadius: 0,
			},
		],
	};

	const options: ChartOptions<"line"> = {
		// Turn off animations and data parsing for performance
		animation: false,
		parsing: false,

		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			mode: "nearest",
			axis: "x",
			intersect: false,
		},
		plugins: {
			legend: {
				position: "top",
				display: true,
				labels: {
					boxWidth: 12,
					boxHeight: 12,
				},
			},
		},
		scales: {
			x: {
				type: "time",
				time: {
					displayFormats: getLabelFormats(minUnit),
				},
				ticks: {
					source: "auto",
					// Disabled rotation for performance
					maxRotation: 0,
					autoSkip: true,
					maxTicksLimit: 7,
				},
			},
			y: {
				beginAtZero: true,
			},
		},
	};

	const height = 150;
	return (
		<>
			{isLoading ? (
				<Skeleton
					variant="rectangular"
					sx={{ bgcolor: "grey.50" }}
					height={height}
					animation="wave"
				/>
			) : (
				<Line
					data={data}
					options={options}
					data-testid="line-chart"
					height={height}
				/>
			)}
		</>
	);
};
