/**
 * Our typography styling
 */
const typography = {
	fontFamily: ['"Poppins"', "Arial", "sans-serif"].join(","),

	h1: {
		fontWeight: 700,
		fontSize: 60,
		letterSpacing: "0.8px",
	},
	h2: {
		fontWeight: 700,
		fontSize: 40,
		marginTop: 8,
		marginBottom: 8,
		letterSpacing: "0.8px",
	},
	h3: {
		fontWeight: 700,
		fontSize: 24,
		letterSpacing: "0.8px",
	},
	h4: {
		fontWeight: 700,
		fontSize: 20,
		letterSpacing: "0.4px",
	},
	h5: {
		fontWeight: 700,
		fontSize: 16,
		letterSpacing: "0.8px",
	},
	h6: {
		fontWeight: 700,
		fontSize: 14,
		letterSpacing: "0.8px",
	},
	p1: {
		fontWeight: 700,
		fontSize: 16,
		letterSpacing: "0.4px",
	},
	p2: {
		fontWeight: 400,
		fontSize: 16,
		letterSpacing: "0.4px",
	},
	p3: {
		fontWeight: 700,
		fontSize: 11,
		letterSpacing: "0.2px",
	},
	p4: {
		fontWeight: 400,
		fontSize: 11,
		letterSpacing: "0.2px",
	},
	subtitle1: {
		fontWeight: 400,
		fontSize: 18,
		letterSpacing: "0.4px",
	},
	subtitle2: {
		fontWeight: 400,
		fontSize: 16,
		letterSpacing: "0.4px",
	},
	body1: {
		fontWeight: 400,
		fontSize: 16,
		letterSpacing: "0.4px",
	},
	body2: {
		fontWeight: 400,
		fontSize: 14,
		letterSpacing: "0.4px",
	},
	caption: {
		fontWeight: 400,
		fontSize: 12,
		letterSpacing: "0.4px",
	},
	button: {
		fontWeight: 400,
		fontSize: 16,
		letterSpacing: "0.4px",
	},
};

export default typography;
