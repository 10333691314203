import { createContext, useContext, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";

// The default value of the dialog
const initialState = {
	title: "",
	content: "",
	actionCallback: (_: boolean) => {},
};

// The typed context
const OkCancelDialogContext = createContext({
	openDialog: (
		_title: string,
		_content: string,
		_actionCallback: (result: boolean) => void
	) => {},
});

// The parameters to the OK Cancel Dialog element
type OkCancelDialogProviderParameters = {
	children: JSX.Element;
};

/**
 * @param props the props as OkCancelDialogProviderParameters
 * @returns a provider for a modal ok cancel dialog
 */
export const OkCancelDialogProvider = ({
	children,
}: OkCancelDialogProviderParameters) => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogConfig, setDialogConfig] = useState(initialState);

	// Set the state and open the dialog
	const openDialog = (
		title: string,
		content: string,
		actionCallback: (_: boolean) => void
	) => {
		setDialogOpen(true);
		setDialogConfig({ title, content, actionCallback });
	};

	// Close the dialog and fire the promise callback with the result
	const closeDialog = (result: boolean) => {
		setDialogOpen(false);
		setDialogConfig(initialState);
		dialogConfig.actionCallback(result);
	};

	return (
		<OkCancelDialogContext.Provider value={{ openDialog }}>
			{children}
			<Dialog
				open={dialogOpen}
				onClose={() => closeDialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{dialogConfig.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{dialogConfig.content}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => closeDialog(false)}>Cancel</Button>
					<Button onClick={() => closeDialog(true)} autoFocus>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</OkCancelDialogContext.Provider>
	);
};

/**
 * @returns the hook to open an OK Cancel dialog
 */
export const useOkCancelDialog = () => {
	// Retrieve the openDialog function
	const { openDialog: openDialogInner } = useContext(OkCancelDialogContext);

	// Wrap the openDialog call with a promise
	const openDialog = (title: string, content: string) =>
		new Promise((res) => {
			openDialogInner(title, content, res);
		});

	return { openDialog };
};
