import { useState } from "react";

/**
 * A hook to get the initial / stored value and a method to save to local storage.
 * 
 * Note: Don't pass a circular object, null or function in the place of value as it uses 'JSON.stringify' for serialization.
 * @param key is the local storage key that will be used
 * @param initialValue used for the first time
 * @returns the value from storage if exists, else returns initialValue and a fn to update storage value
 */
export const useLocalStore = <T>(key: string, initialValue: T) => {
	const [value, setValue] = useState<T>(() => {
		const storeValue = localStorage.getItem(key);
		if (storeValue !== null) {
			return JSON.parse(storeValue);
		} else {
			return initialValue;
		}
	});

	const saveToStore = (value: T) => {
		setValue(value);
		localStorage.setItem(key, JSON.stringify(value));
	}

	return [value, saveToStore] as const;
}