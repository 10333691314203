import {
	Box,
	Typography,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	IconButton,
} from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import {
	useGetEvents,
	useGetComponents,
	useGetEngine,
} from "../../../api/engine";
import { MapOpenTelemetrySeverityToEventSeverity } from "../../../rhapsody-stats";
import EventIcons from "../../../components/events/event-icons";
import { Event, Events, Component } from "../../../api/api.generated";
import { timeAgo } from "../../../utils/duration-util";
import { useEffect, useState } from "react";

/**
 * The properties for the engine event list. Exported for testing.
 */
export type EngineEventListProps = {
	id: string;
	link: string;
	selectedSeverity: string[];
};

/**
 * Holds the component and if it is of type 'ROOT'
 */
export type EventComponent = {
	isRoot: boolean;
	component?: Component;
};

const compareSeverityAndTime = (a: Event, b: Event): number => {
	return (
		b.severity - a.severity ||
		Date.now() -
			Date.parse(a.timestamp) -
			(Date.now() - Date.parse(b.timestamp))
	);
};

/**
 * Get component from component map matching the event.component
 * @param componentMap
 * @param event
 * @param engineName
 * @returns component if exist, else returns undefined and logs an error
 */
const getComponentFromMap = (
	componentMap: Map<string, Component>,
	event: Event,
	engineName: string
): Component | undefined => {
	const component = componentMap.get(event.component!);
	if (component === undefined) {
		console.error(
			`Unable to find the event component ${event.component} for engine ${engineName}, please review the component.`
		);
	}
	return component;
};

/**
 * List of engine events by severity. Provides a link to the related component
 * in Rhapsody WMC.
 *
 * @param param0 the parameters as EngineEventListProps
 * @returns
 */
const EngineEventsList = ({
	id,
	link,
	selectedSeverity,
}: EngineEventListProps) => {
	const [shouldDisplayEventList, setShouldDisplayEventList] = useState(false);
	const { events } = useGetEvents(id, (data, apiStatus) => {
		if (apiStatus.isSuccess && data) {
			return {
				events: data
					.filter((event) =>
						selectedSeverity.includes(
							MapOpenTelemetrySeverityToEventSeverity(
								event.severity
							) ?? ""
						)
					)
					// Show highest severity events first
					.sort(compareSeverityAndTime),
			};
		}
		return { events: [] };
	});

	const { componentMap } = useGetComponents(id, (data, apiStatus) => {
		if (apiStatus.isSuccess && data) {
			return {
				componentMap: new Map<string, Component>(
					data.map((component) => [component.id, component])
				),
			};
		}
		return { componentMap: new Map() };
	});

	const { name } = useGetEngine(id, (data, apiStatus) => {
		if (apiStatus.isSuccess && data) {
			return {
				name: data.name,
			};
		}
		return {
			name: "",
		};
	});

	useEffect(() => {
		// Display the event list when either of componentMap or events with component 'ROOT' exist
		if (
			componentMap.size > 0 ||
			events.filter((e) => e.component === "ROOT").length > 0
		) {
			setShouldDisplayEventList(true);
		}
	}, [componentMap, events]);

	if (
		shouldDisplayEventList &&
		events.length > 0 &&
		selectedSeverity.length
	) {
		return (
			<EventsTable
				link={link}
				events={events}
				componentMap={componentMap}
				engineName={name}
			/>
		);
	} else {
		return null;
	}
};

type EventsTableProps = {
	link: string;
	events: Events;
	componentMap: Map<string, Component>;
	engineName: string;
};

const EventsTable = ({
	link,
	events,
	componentMap,
	engineName,
}: EventsTableProps) => {
	return (
		<Box sx={{ paddingTop: "1em" }}>
			<TableContainer
				sx={{
					border: 1,
					borderColor: "background.default",
					borderRadius: "4px",
					maxHeight: 200,
					overflow: "auto",
				}}
			>
				<Table size="small" data-testid="event-list-table">
					<TableBody>
						{events.map((event: Event, index: number) => (
							<Row
								key={`${event.timestamp}-${event.eventType}`}
								index={index}
								link={link}
								event={event}
								component={
									event.component === "ROOT"
										? { isRoot: true }
										: {
												isRoot: false,
												component: getComponentFromMap(
													componentMap,
													event,
													engineName
												),
										  }
								}
								engineName={engineName}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

type RowProps = {
	link: string;
	event: Event;
	component: EventComponent;
	index: number;
	engineName: string;
};

const Row = ({ link, event, component, index, engineName }: RowProps) => {
	return (
		<TableRow data-testid={`event-list-row-${index}`}>
			<TableCell data-testid={`event-list-icon-${index}`}>
				<EventIcons
					severity={MapOpenTelemetrySeverityToEventSeverity(
						event.severity
					)}
				/>
			</TableCell>
			<TableCell data-testid={`event-list-component-${index}`}>
				{component.isRoot ? engineName : component.component?.name}
			</TableCell>
			<TableCell data-testid={`event-list-body-${index}`}>
				<Typography variant="body2" sx={{ marginTop: "6px" }}>
					{event.body?.comment ||
						event.body?.name ||
						event.body?.message}
				</Typography>
				<Typography
					variant="caption"
					color="text.secondary"
					sx={{ marginBottom: "6px" }}
				>
					{timeAgo(event.timestamp)}
				</Typography>
			</TableCell>
			<TableCell
				padding="none"
				sx={{ paddingRight: "5px" }}
				data-testid={`event-list-link-${index}`}
			>
				<IconButton
					color="primary"
					href={
						link &&
						(component.isRoot
							? `${link}/status/ViewSystemMonitor.action`
							: getComponentLink(link, component))
					}
					target="_blank"
					rel="noreferrer"
				>
					<OpenInNew />
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

/**
 * Builds a link that points to the component on the WMC
 * @param link
 * @param component
 * @returns a link to the component if the component exist, else returns an empty string
 * Exported for testing.
 */
export const getComponentLink = (link: string, component: EventComponent) => {
	if (!component.component) {
		return "";
	}
	const url = componentUrl[component.component.type as ComponentType];
	return `${link}/component/${url.componentType}/${url.action}.action?id=${url.idPrefix}-${component.component.id}`;
};

export type ComponentType = "CP" | "ROUTE" | "WEBSERVICE" | "REST";
type UrlElements = {
	componentType: string;
	action: string;
	idPrefix: string;
};

const componentUrl: Record<ComponentType, UrlElements> = {
	CP: {
		componentType: "communicationpoint",
		action: "ViewCommunicationPoint",
		idPrefix: "commpoint",
	},
	ROUTE: { componentType: "route", action: "ViewRoute", idPrefix: "route" },
	WEBSERVICE: {
		componentType: "webservice",
		action: "ViewWebService",
		idPrefix: "webservice",
	},
	REST: {
		componentType: "restclient",
		action: "ViewRestClient",
		idPrefix: "restclient",
	},
};

export default EngineEventsList;
