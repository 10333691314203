import { datadogRum } from "@datadog/browser-rum";

/**
 * Helper method to setup Datadog based on the deploy env via env props
 */
export const SetupDatadog = () => {
	datadogRum.init({
		applicationId: process.env.REACT_APP_RUM_APPLICATION_ID!,
		clientToken: process.env.REACT_APP_RUM_CLIENT_TOKEN!,
		site: "datadoghq.com",
		service: "console",
		env: process.env.REACT_APP_DEPLOY_ENV ?? "unknown",
		version: "0.1",
		sampleRate: 100,
		trackInteractions: true,
		defaultPrivacyLevel: "mask-user-input",
		silentMultipleInit: true,
	});

	datadogRum.startSessionReplayRecording();
};
