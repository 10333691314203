import {
	Box,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { Statistics } from "../../../api/api.generated";
import { useGetStatistics } from "../../../api/engine";
import {
	ChartjsLineChart,
	TimeUnit,
} from "../../../components/charts/linechart";
import AlertBanner, {
	AlertType,
} from "../../../components/alert-banners/alert-banner";
import {
	MESSAGES_ATT_FAILED,
	MESSAGES_ATT_PROCESSED,
	MESSAGES_ATT_RECEIVED,
	MESSAGES_ATT_SENT,
	MESSAGES_METRIC,
	ParseStatType,
} from "../../../rhapsody-stats";

type ThroughputProps = {
	id: string;
};

// A 1 hour time span
export const span1H = 3600;
// A 1 day time span
export const span1D = 3600 * 24;
// A 1 week time span
export const span1W = 3600 * 24 * 7;
// A 1 month time span
export const span1M = 3600 * 24 * 30;
// A 3 month time span
export const span3M = 3600 * 24 * 30 * 3;

/**
 * The possible values for time spans
 */
export enum TimeSpan {
	Hour = "1H",
	Day = "1D",
	Week = "1W",
	Month = "1M",
	Months3 = "3M",
}

const getDurationSeconds = (span: TimeSpan): number => {
	switch (span) {
		case TimeSpan.Hour:
			return span1H;
		case TimeSpan.Day:
			return span1D;
		case TimeSpan.Week:
			return span1W;
		case TimeSpan.Month:
			return span1M;
		case TimeSpan.Months3:
			return span3M;
		default:
			console.log(`Invalid span=${span}`);
			return span1H;
	}
};

const getData = (
	statData: Statistics,
	attributes: { [key: string]: string }
): Array<{ x: number; y: number }> => {
	const data = ParseStatType(statData, MESSAGES_METRIC, attributes);
	if (data) {
		// Convert the [][timestamp in seconds, value] entries into []{x:timestamp in milliseconds, y:value}
		return data.map((v) => ({
			x: v[0] * 1000,
			y: v[1],
		}));
	}
	return [];
};

/**
 * @param param0 the parameters as ThroughputProps
 * @returns the engine throughput graph
 */
const Throughput = ({ id }: ThroughputProps) => {
	const [duration, setDuration] = useState(span1H);
	const span = useRef(TimeSpan.Hour);
	const isLoading = useRef(true);
	let minUnit: TimeUnit;

	const handleSpanChange = (
		_: React.MouseEvent<HTMLElement>,
		newSpan: TimeSpan
	) => {
		if (newSpan !== null) {
			setDuration(getDurationSeconds(newSpan));
			span.current = newSpan;
		}
	};

	const { data: statData, apiStatus } = useGetStatistics(
		id,
		0,
		duration,
		[MESSAGES_METRIC],
		1000,
		(data, apiStatus) => {
			return { data, apiStatus };
		}
	);

	if (duration > span1D) {
		minUnit = "day";
	} else {
		minUnit = "hour";
	}
	let pointDataProcessed: { x: number; y: number }[] = [],
		pointDataReceived: { x: number; y: number }[] = [],
		pointDataSent: { x: number; y: number }[] = [],
		pointDataFailed: { x: number; y: number }[] = [];
	if (statData?.data) {
		isLoading.current = true;
		pointDataProcessed = getData(statData, MESSAGES_ATT_PROCESSED);
		pointDataReceived = getData(statData, MESSAGES_ATT_RECEIVED);
		pointDataSent = getData(statData, MESSAGES_ATT_SENT);
		pointDataFailed = getData(statData, MESSAGES_ATT_FAILED);
		isLoading.current = false;
	}

	return (
		<div data-testid="throughput">
			<Box display="flex" alignItems="center">
				<Typography variant="h4">Messages</Typography>
				<Box flexGrow="1" />
				<ToggleButtonGroup
					color="primary"
					value={span.current}
					exclusive
					onChange={handleSpanChange}
					data-testid="span-select"
				>
					<ToggleButton
						value={TimeSpan.Hour}
						data-testid={TimeSpan.Hour}
					>
						{TimeSpan.Hour}
					</ToggleButton>
					<ToggleButton
						value={TimeSpan.Day}
						data-testid={TimeSpan.Day}
					>
						{TimeSpan.Day}
					</ToggleButton>
					<ToggleButton
						value={TimeSpan.Week}
						data-testid={TimeSpan.Week}
					>
						{TimeSpan.Week}
					</ToggleButton>
					<ToggleButton
						value={TimeSpan.Month}
						data-testid={TimeSpan.Month}
					>
						{TimeSpan.Month}
					</ToggleButton>
					<ToggleButton
						value={TimeSpan.Months3}
						data-testid={TimeSpan.Months3}
					>
						{TimeSpan.Months3}
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>
			<div>
				{apiStatus.isError ? (
					<AlertBanner
						alertType={AlertType.Error}
						header="Error"
						message="Error loading the throughput, try reloading the page."
						details={JSON.stringify(apiStatus.error)}
					/>
				) : (
					<ChartjsLineChart
						lineDataProcessed={pointDataProcessed}
						lineDataReceived={pointDataReceived}
						lineDataSent={pointDataSent}
						lineDataFailed={pointDataFailed}
						minUnit={minUnit}
						isLoading={isLoading.current || apiStatus.isLoading}
					/>
				)}
			</div>
		</div>
	);
};

export default Throughput;
