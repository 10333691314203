import { Box } from "@mui/material";
import { ReactNode } from "react";

type OrgPanelProps = {
	children: ReactNode;
	value: string;
	selectedOrg: string;
};

/**
 * @param props the parameters as OrgPanelProps
 * @returns a org panel that can be hidden or shown
 */
const OrgPanel = (props: OrgPanelProps) => {
	const { children, value, selectedOrg, ...other } = props;
	return (
		<div role="tabpanel" hidden={value !== selectedOrg} {...other}>
			{value === selectedOrg && <Box py={3}>{children}</Box>}
		</div>
	);
};

export default OrgPanel;
