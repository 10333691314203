import { useSnackbar } from "notistack";
import { ApiStatus } from "./api.enhanced";
import {
	Organization,
	Tokens,
	useDeleteOrganizationMutation,
	useDeleteTokenMutation,
	useDeleteUserOrganizationMutation,
	useGetOrganizationQuery,
	useGetOrganizationUsersQuery,
	useGetTokenQuery,
	useGetUserOrganizationsQuery,
	usePostOrganizationMutation,
	usePostTokenMutation,
	usePutUserOrganizationMutation,
	User,
} from "./api.generated";
import { useMemoSelector } from "./memoSelector";
import { showSnackWithResponse } from "./mutationSnackbarHelper";

/**
 * Regex for token names.
 * Valid characters are unicode letters, unicode numbers, or _
 */
export const validTokenNameRegex = /^[\p{L}\p{N}_]+$/u;

// The toast message when org creation is successful
export const PostOrgSuccessMessage = "Created organization";
// The toast message when org creation fails
export const PostOrgFailureMessage = "Failed to create organization";

// The toast message when user add is successful
export const PutUserSuccessMessage = "Added user";
// The toast message when user add fails
export const PutUserFailureMessage = "Failed to add user";

// The toast message when user remove is successful
export const DeleteUserSuccessMessage = "Removed user";
// The toast message when user remove fails
export const DeleteUserFailureMessage = "Failed to remove user";

// The toast message when token creation is successful
export const PostTokenSuccessMessage = "Created token";
// The toast message when token creation fails
export const PostTokenFailureMessage = "Failed to create token";

// The toast message when token deletion is successful
export const DeleteTokenSuccessMessage = "Deleted token";
// The toast message when token deletion fails
export const DeleteTokenFailureMessage = "Failed to delete token";

// The toast message when org deletion is successful
export const DeleteOrgSuccessMessage = "Deleted organization";
// The toast message when org deletion fails
export const DeleteOrgFailureMessage = "Failed to delete organization";

/**
 * A wrapper around the organizations API call
 *
 * @returns the organizations the user is apart of
 */
export const useGetOrganizations = (): {
	organizations: string[];
	apiStatus: ApiStatus;
} => {
	const selector = useMemoSelector(
		(data: string[] | undefined, apiStatus: ApiStatus) => {
			if (apiStatus.isSuccess && data) {
				return {
					organizations: data,
					apiStatus: apiStatus,
				};
			}
			return {
				organizations: [],
				apiStatus: apiStatus,
			};
		},
		"getOrganization"
	);
	return useGetUserOrganizationsQuery(undefined, {
		selectFromResult: (res) => selector(res.data, res),
	});
};

/**
 * A wrapper around the new organization mutators
 *
 * @returns the mutator to create a new organization
 */
export const useOrganizationMutator = () => {
	const [postOrganization] = usePostOrganizationMutation();
	const { enqueueSnackbar } = useSnackbar();

	return {
		postOrganization: async (
			name: string
		): Promise<{ data?: any; error?: any }> => {
			const result = await postOrganization({ organization: { name } });
			showSnackWithResponse(
				enqueueSnackbar,
				result,
				PostOrgSuccessMessage,
				PostOrgFailureMessage
			);
			return result;
		},
	};
};

/**
 * A wrapper around the organization API call that filters to just return the name
 *
 * @param id the organization id
 * @returns the name of the organization
 */
export const useGetOrganizationName = (id: string): string => {
	const selector = useMemoSelector(
		(data: Organization | undefined, apiStatus: ApiStatus) => {
			if (apiStatus.isSuccess && data) {
				return {
					name: data.name,
				};
			}
			return { name: "" };
		},
		"getOrganizationName"
	);
	const { name } = useGetOrganizationQuery(
		{
			organizationId: id,
		},
		{
			selectFromResult: (res) => selector(res.data, res),
		}
	);
	return name;
};

/**
 * A wrapper around the organization users API call
 *
 * @param id the organization id
 * @returns the organization users
 */
export const useGetOrganizationUsers = (id: string): User[] => {
	const selector = useMemoSelector(
		(data: User[] | undefined, apiStatus: ApiStatus) => {
			if (apiStatus.isSuccess && data) {
				return {
					users: data,
				};
			}
			return { users: [] };
		},
		"getOrganizationUsers"
	);
	const { users } = useGetOrganizationUsersQuery(
		{
			organizationId: id,
		},
		{
			selectFromResult: (res) => selector(res.data, res),
		}
	);
	return users;
};

/**
 * A wrapper around the existing organization mutators
 *
 * @param id the organization id
 * @returns the mutators for adding a user, deleting a user, creating a token, deleting a token, and deleting an organization, for the given organization
 */
export const useExistingOrganizationMutator = (id: string) => {
	const [putUser] = usePutUserOrganizationMutation();
	const [deleteUser] = useDeleteUserOrganizationMutation();
	const [postToken] = usePostTokenMutation();
	const [deleteToken] = useDeleteTokenMutation();
	const [deleteOrganization] = useDeleteOrganizationMutation();
	const { enqueueSnackbar } = useSnackbar();

	return {
		putUser: async (userIss: string, userSub: string) => {
			const result = await putUser({
				organizationId: id,
				userIss: encodeURIComponent(userIss),
				userSub: encodeURIComponent(userSub),
			});
			showSnackWithResponse(
				enqueueSnackbar,
				result,
				PutUserSuccessMessage,
				PutUserFailureMessage
			);
		},
		deleteUser: async (userIss: string, userSub: string) => {
			const result = await deleteUser({
				organizationId: id,
				userIss: encodeURIComponent(userIss),
				userSub: encodeURIComponent(userSub),
			});
			showSnackWithResponse(
				enqueueSnackbar,
				result,
				DeleteUserSuccessMessage,
				DeleteUserFailureMessage
			);
		},
		postToken: async (name: string) => {
			if (validTokenNameRegex.test(name)) {
				const result = await postToken({ organizationId: id, name });
				showSnackWithResponse(
					enqueueSnackbar,
					result,
					PostTokenSuccessMessage,
					PostTokenFailureMessage
				);
			} else {
				enqueueSnackbar(PostTokenFailureMessage, {
					variant: "error",
				});
			}
		},
		deleteToken: async (name: string) => {
			if (validTokenNameRegex.test(name)) {
				const result = await deleteToken({ organizationId: id, name });
				showSnackWithResponse(
					enqueueSnackbar,
					result,
					DeleteTokenSuccessMessage,
					DeleteTokenFailureMessage
				);
			} else {
				enqueueSnackbar(DeleteTokenFailureMessage, {
					variant: "error",
				});
			}
		},
		deleteOrganization: async () => {
			const result = await deleteOrganization({ organizationId: id });
			showSnackWithResponse(
				enqueueSnackbar,
				result,
				DeleteOrgSuccessMessage,
				DeleteOrgFailureMessage
			);
		},
	};
};

/**
 * A wrapper around the organization users API call
 *
 * @param id the organization id
 * @returns the tokens in an organization
 */
export const useGetOrganizationTokens = (id: string): Tokens => {
	const selector = useMemoSelector(
		(data: Tokens | undefined, apiStatus: ApiStatus) => {
			if (apiStatus.isSuccess && data) {
				return {
					tokens: data,
				};
			}
			return { tokens: [] };
		},
		"getOrganizationTokens"
	);
	const { tokens } = useGetTokenQuery(
		{
			organizationId: id,
		},
		{
			selectFromResult: (res) => selector(res.data, res),
		}
	);
	return tokens;
};
