import { api, Engines, Tags, Uptime } from "./api.generated";

/**
 * The responses from the api request.
 */
export type ApiStatus = {
	isSuccess: boolean;
	isLoading: boolean;
	isError: boolean;
	isFetching: boolean;
	error?: unknown;
};

/**
 * A type to hold the current time with the uptime.
 */
export type UptimeEnhanced = Uptime & { currentTime?: number };

/**
 * Adds a tag to a given engine in an optimistic update
 *
 * @param draft the draft engines list
 * @param engine the engine to add the tag to
 * @param tag the tag to add
 */
export const AddTagForEngine = (
	draft: Engines,
	engine: string,
	tag: string
) => {
	for (const draftEngine of draft) {
		if (draftEngine.id === engine && draftEngine.tags.indexOf(tag) === -1) {
			draftEngine.tags.push(tag);
		}
	}
};

/**
 * Deletes the tag from a given engine in an optimistic update
 *
 * @param draft the draft engines list
 * @param engine the engine to delete the tag from
 * @param tag the tag to delete
 */
export const DeleteTagForEngineAndCheckStillExists = (
	draft: Engines,
	engine: string,
	tag: string
) => {
	let tagStillExists = false;
	for (const draftEngine of draft) {
		if (draftEngine.id === engine) {
			// Remove the tag from this engine
			const index = draftEngine.tags.indexOf(tag);
			if (index !== -1) {
				draftEngine.tags.splice(index, 1);
			}
		} else {
			// Keep a record as to whether we still have this tag
			tagStillExists =
				tagStillExists || draftEngine.tags.indexOf(tag) !== -1;
		}
	}
	return tagStillExists;
};

/**
 * Adds the tag to a list of tags in an optimistic update
 *
 * @param draft the draft tags list
 * @param tag the tag to add
 */
export const AddTagToTagList = (draft: Tags, tag: string) => {
	if (draft.indexOf(tag) === -1) {
		draft.push(tag);
	}
};

/**
 * Deletes the tag from a list of tags in an optimistic update
 *
 * @param draft the draft tags list
 * @param tag the tag to delete
 */
export const DeleteTagFromTagList = (draft: Tags, tag: string) => {
	const index = draft.indexOf(tag);
	if (index !== -1) {
		draft.splice(index, 1);
	}
};

export const enhancedApi = api.enhanceEndpoints({
	addTagTypes: [
		"tags",
		"organizations",
		"organization-users",
		"tokens",
		"engines",
	],
	endpoints: {
		putTag: {
			invalidatesTags: ["tags"],
			onQueryStarted(
				{ engine, tag },
				{ dispatch, queryFulfilled, getState }
			) {
				// Loop through all the endpoints that will be invalidated by our put
				for (const {
					endpointName,
					originalArgs,
				} of enhancedApi.util.selectInvalidatedBy(getState(), [
					"tags",
				])) {
					if (endpointName === "getEngine") {
						// Set the tag on this engine response
						const patch = dispatch(
							api.util.updateQueryData(
								endpointName,
								originalArgs,
								(draft) => {
									AddTagForEngine(draft, engine, tag);
								}
							)
						);
						queryFulfilled.catch(patch.undo);
					} else if (endpointName === "getTag") {
						// Push the new tag into the list of tags
						const patch = dispatch(
							api.util.updateQueryData(
								endpointName,
								originalArgs,
								(draft) => {
									AddTagToTagList(draft, tag);
								}
							)
						);
						queryFulfilled.catch(patch.undo);
					}
				}
			},
		},
		deleteTag: {
			invalidatesTags: ["tags"],
			onQueryStarted(
				{ engine, tag },
				{ dispatch, queryFulfilled, getState }
			) {
				// Loop through all the endpoints that will be invalidated by our delete
				let tagStillExists = false;
				for (const {
					endpointName,
					originalArgs,
				} of enhancedApi.util.selectInvalidatedBy(getState(), [
					"tags",
				])) {
					if (endpointName === "getEngine") {
						// Set the tag on this engine response
						const patch = dispatch(
							api.util.updateQueryData(
								endpointName,
								originalArgs,
								// eslint-disable-next-line no-loop-func
								(draft) => {
									tagStillExists =
										DeleteTagForEngineAndCheckStillExists(
											draft,
											engine,
											tag
										);
								}
							)
						);
						queryFulfilled.catch(patch.undo);
					}
				}
				// Delete the tag from the list if its no longer used
				if (!tagStillExists) {
					for (const {
						endpointName,
						originalArgs,
					} of enhancedApi.util.selectInvalidatedBy(getState(), [
						"tags",
					])) {
						if (endpointName === "getTag") {
							// Delete the tag from the tag list
							const patch = dispatch(
								api.util.updateQueryData(
									endpointName,
									originalArgs,
									(draft) => {
										DeleteTagFromTagList(draft, tag);
									}
								)
							);
							queryFulfilled.catch(patch.undo);
						}
					}
				}
			},
		},
		getTag: {
			providesTags: ["tags"],
		},
		getEngine: {
			providesTags: ["tags", "engines"],
		},

		getUserOrganizations: {
			providesTags: ["organizations"],
		},
		postOrganization: {
			invalidatesTags: ["organizations"],
		},
		deleteOrganization: {
			invalidatesTags: ["organizations"],
		},

		getOrganizationUsers: {
			providesTags: ["organization-users"],
		},
		getUserByEmail: {
			providesTags: ["organization-users"],
		},
		putUserOrganization: {
			invalidatesTags: ["organization-users"],
		},
		deleteUserOrganization: {
			invalidatesTags: ["organization-users"],
		},

		getToken: {
			providesTags: ["tokens", "engines"],
		},
		postToken: {
			invalidatesTags: ["tokens"],
		},
		deleteToken: {
			invalidatesTags: ["tokens"],
		},

		deleteEngine: {
			invalidatesTags: ["engines"],
		},

		getUptime: {
			transformResponse(response: UptimeEnhanced) {
				return { ...response, currentTime: Date.now() };
			},
		},
	},
});
