import { createTheme } from "@mui/material";
import typography from "./typography";

/**
 * Our theme for MUI
 */
const theme = createTheme({
	palette: {
		background: {
			default: "#d9dfe6",
			paper: "#ffffff",
		},
		primary: {
			contrastText: "#ffffff",
			main: "#1a81f4",
		},
		text: {
			primary: "#050f19",
			secondary: "#7e858c",
		},
	},
	typography: typography,
	components: {
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: 16,
					"&:last-child": {
						paddingBottom: 16,
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: "none",
				},
			},
		},
	},
	breakpoints: {
		values: {
			// Should match with breakpoints.scss
			xs: 0,
			sm: 600,
			md: 970,
			lg: 1200,
			xl: 1536,
		},
	},
});

export default theme;
