import { useSnackbar } from "notistack";
import { ApiStatus } from "./api.enhanced";
import {
	Tags,
	useDeleteTagMutation,
	useGetTagQuery,
	usePutTagMutation,
} from "./api.generated";
import { useMemoSelector } from "./memoSelector";
import { showSnackWithResponse } from "./mutationSnackbarHelper";

/**
 * Regex for tag names.
 * Valid characters are unicode letters, unicode numbers, or _
 */
export const validTagRegex = /^[\p{L}\p{N}_]+$/u;

// The toast message when tag add is successful
export const AddTagSuccessMessage = "Added tag";
// The toast message when tag add fails
export const AddTagFailureMessage = "Failed to add tag";

// The toast message when tag delete is successful
export const DeletedTagSuccessMessage = "Deleted tag";
// The toast message when tag delete fails
export const DeletedTagFailureMessage = "Failed to delete tag";

/**
 * A wrapper around the tags API call
 *
 * @returns all the tags available for the user
 */
export const useGetAllTags = () => {
	const selector = useMemoSelector(
		(data: Tags | undefined, apiStatus: ApiStatus) => {
			if (apiStatus.isSuccess && data) {
				return {
					allTags: data,
					apiStatus,
				};
			}
			return {
				allTags: [],
				apiStatus,
			};
		},
		"getAllTags"
	);
	const { allTags, apiStatus } = useGetTagQuery(undefined, {
		selectFromResult: (res) => selector(res.data, res),
	});
	return { allTags, apiStatus };
};

/**
 * A wrapper around engine tag mutators
 *
 * @param engineId the engine id
 * @returns the mutators for adding and deleting a tag for the given engine
 */
export const useTagMutator = (engineId: string) => {
	const [putTagMutator] = usePutTagMutation();
	const [deleteTagMutator] = useDeleteTagMutation();
	const { enqueueSnackbar } = useSnackbar();

	return {
		putTag: async (tag: string) => {
			if (validTagRegex.test(tag)) {
				const result = await putTagMutator({ engine: engineId, tag });
				showSnackWithResponse(
					enqueueSnackbar,
					result,
					AddTagSuccessMessage,
					AddTagFailureMessage
				);
			} else {
				enqueueSnackbar(AddTagFailureMessage, {
					variant: "error",
				});
			}
		},
		deleteTag: async (tag: string) => {
			if (validTagRegex.test(tag)) {
				const result = await deleteTagMutator({
					engine: engineId,
					tag,
				});
				showSnackWithResponse(
					enqueueSnackbar,
					result,
					DeletedTagSuccessMessage,
					DeletedTagFailureMessage
				);
			} else {
				enqueueSnackbar(DeletedTagFailureMessage, {
					variant: "error",
				});
			}
		},
	};
};
