import { TextField, Autocomplete } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { useState } from "react";
import {
	useGetAllTags,
	useTagMutator,
	validTagRegex,
} from "../../../../api/tag";
import { useGetEngine } from "../../../../api/engine";
const deepEqual = require("deep-equal");

type EngineTagsProps = {
	engine: string;
};

/**
 * @param props the parameters as EngineTagsProps
 * @returns the tag display for the engine
 */
const EngineTags = (props: EngineTagsProps) => {
	// List of all the tags available to be picked
	const { allTags } = useGetAllTags();
	// Tags for this engine
	const { engineTags, apiStatus } = useGetEngine(
		props.engine,
		(data, apiStatus) => {
			if (apiStatus.isSuccess && data) {
				return {
					engineTags: data.tags,
					apiStatus,
				};
			}
			return { engineTags: [], apiStatus };
		}
	);

	// API calls for adding/deleting tags
	const tagMutator = useTagMutator(props.engine);

	// Internal state management for the control
	const [value, setValue] = useState<string[]>(engineTags);
	const filter = createFilterOptions<string>({
		ignoreCase: true,
	});

	if (!deepEqual(engineTags, value)) {
		setValue(engineTags);
	}

	return (
		<Autocomplete
			multiple
			filterSelectedOptions // remove options from dropdown that are already selected
			options={allTags} // binding from the API call
			value={value} // binding of the selected items
			size="small"
			data-testid="engine-tags"
			data-test-is-fetching={apiStatus.isFetching}
			renderInput={(params) => (
				<TextField variant="outlined" label="Tags" {...params} />
			)} // the field the chips are rendered into
			onChange={(_, newValue) => {
				setValue(newValue);
				// Tags that exist in newValue, but not in value (ie added tags)
				newValue
					.filter((item) => value.indexOf(item) < 0)
					.forEach((tag) => tagMutator.putTag(tag));
				// Tags that exist in value, but not in newValue (ie deleted tags)
				value
					.filter((item) => newValue.indexOf(item) < 0)
					.forEach((tag) => tagMutator.deleteTag(tag));
			}}
			filterOptions={(options, params) => {
				// Perform a regular filtering
				const filtered = filter(options, params);
				// Suggest the creation of a new value
				if (
					params.inputValue !== "" &&
					!allTags.find(
						(t) =>
							t.toLowerCase() === params.inputValue.toLowerCase()
					) &&
					validTagRegex.test(params.inputValue)
				) {
					filtered.push(params.inputValue);
				}
				return filtered;
			}}
			renderOption={(props, option) => {
				return (
					<li {...props}>
						{allTags.indexOf(option) === -1
							? `Add "${option}"`
							: option}
					</li>
				);
			}} // displayed inside the drop down
			selectOnFocus
			clearOnBlur
			handleHomeEndKeys
			ChipProps={{
				color: "primary",
			}}
			autoHighlight
		/>
	);
};

export default EngineTags;
