/**
 * A helper function to truncate a number to a given number of decimal digits
 *
 * @param number the number
 * @param digits the number of digits
 * @returns the truncated number
 */
export const TruncateDecimal = (number: number, digits: number) => {
	let num = number.toString();
	return num.indexOf(".") >= 0
		? parseFloat(num.substring(0, num.indexOf(".") + (digits + 1)))
		: number;
};
