import {
	BaseQueryFn,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { setIsLoggedIn } from "../app/redux/auth-slice";

// The auth required text (in lowercase) to match in order to perform a redirect
export const AuthRequiredText = "authorization required";
// The auth expired text (in lowercase) to match in order to perform a redirect
export const AuthExpiredText = "authorization expired";
// Create a new mutex for preventing multiple auth requests.
const mutex = new Mutex();

/**
 * The url for the API.
 *
 * @returns the url for the API.
 */
export const GetBaseUrl = () => {
	// This is set within .env.development
	if (process.env.REACT_APP_CONSOLE_API_PORT!) {
		return `http://${window.location.hostname}:${process.env.REACT_APP_CONSOLE_API_PORT}/api`;
	}
	// This is normally in production
	return `https://api.${window.location.hostname}/api`;
};

const customFetchBaseQuery = fetchBaseQuery({
	baseUrl: GetBaseUrl(),
	prepareHeaders: async (headers) => {
		headers.set("X-No-CSRF", "1"); /* Anti-CSRF header */
		return headers;
	},
	credentials: "include",
});

/**
 * Our custom query that is a drop in replacement for the default fetchBaseQuery and provides auth.
 * All parameters are passed through to it and its result is returned.
 */
export const customFetchBaseQueryWithReauth: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	// Wait for the mutex to be unlocked. The mutex will only be locked when we are attempting to get new cookies.
	await mutex.waitForUnlock();

	// Try perform the query
	let result = await customFetchBaseQuery(args, api, extraOptions);

	// If we get back a 400 error
	if (
		result.error &&
		(result.error.status === 400 ||
			(result.error.status === "PARSING_ERROR" &&
				result.error.originalStatus === 400))
	) {
		// See what the response error was, and determine if we want to perform further action
		const errorData = result.error.data as string;
		if (errorData) {
			// Test whether it is a auth based error and try to obtain new auth
			if (
				errorData.toLowerCase().includes(AuthRequiredText) ||
				errorData.toLowerCase().includes(AuthExpiredText)
			) {
				// Only attempt to obtain new auth once. Upon entry the mutex is immediately locked.
				if (!mutex.isLocked()) {
					const release = await mutex.acquire();
					try {
						if (
							errorData.toLowerCase().includes(AuthRequiredText)
						) {
							api.dispatch(setIsLoggedIn(false));
						} else if (
							errorData.toLowerCase().includes(AuthExpiredText)
						) {
							// try to get a new token
							const authResult = await customFetchBaseQuery(
								"/auth/refresh",
								api,
								extraOptions
							);
							if (authResult.error) {
								api.dispatch(setIsLoggedIn(false));
							} else {
								// Retry the initial query with the refreshed auth.
								result = await customFetchBaseQuery(
									args,
									api,
									extraOptions
								);
							}
						}
					} finally {
						// Always release.
						release();
					}
				} else {
					// Wait until the mutex is unlocked to try again, so that it uses any newly obtained auth.
					await mutex.waitForUnlock();
					result = await customFetchBaseQuery(
						args,
						api,
						extraOptions
					);
				}
			}
		}
	}
	return result;
};
