import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type EngineSeverity = {
	id: string;
	severity: number;
};

type EngineSeverityState = {
	engineSeverities: EngineSeverity[];
};

const initialState: EngineSeverityState = {
	engineSeverities: [],
};

/**
 * The slice to hold current engine's highest severities
 */
const engineSeveritySlice = createSlice({
	name: "engineSeverity",
	initialState: initialState,
	reducers: {
		addEngineSeverity: (state, action: PayloadAction<EngineSeverity>) => {
			let severityExists = false;
			const engineSeverities = state.engineSeverities.map((severity) => {
				if (severity.id === action.payload.id) {
					severityExists = true;
				}
				return severity;
			});
			if (!severityExists) {
				engineSeverities.push(action.payload);
				if (engineSeverities.length > 1) {
					sortBySeverity(engineSeverities);
				}
			}
			return { ...state, engineSeverities: [...engineSeverities] };
		},
		updateEngineSeverity: (
			state,
			action: PayloadAction<EngineSeverity>
		) => {
			let stateChanged = false;
			state.engineSeverities.forEach((es) => {
				if (
					es.id === action.payload.id &&
					es.severity !== action.payload.severity
				) {
					es.severity = action.payload.severity;
					stateChanged = true;
				}
			});
			if (stateChanged && state.engineSeverities.length > 1) {
				sortBySeverity(state.engineSeverities);
			}
		},
	},
});

/**
 * Reducer functions that can be dispatched to add or update engine severity
 */
export const { addEngineSeverity, updateEngineSeverity } =
	engineSeveritySlice.actions;

/**
 * The reducer to add to the redux store
 */
export default engineSeveritySlice.reducer;

/**
 * Selector function that can be called to get engine severities
 */
export const getEngineSeverities = createSelector(
	(state: RootState) => state.engineSeverity.engineSeverities,
	(values) => {
		return values;
	}
);

/**
 * Selector function that can be called to get engine severity
 */
export const getEngineSeverity = createSelector(
	[
		(state: RootState) => state.engineSeverity.engineSeverities,
		(_, id: string) => id,
	],
	(values, id) => {
		return values.find((x) => x.id === id);
	}
);


const sortBySeverity = (engineSeverities: EngineSeverity[]) => {
	engineSeverities.sort((a, b) => {
		if (a && a.severity && b && b.severity) {
			return b.severity - a.severity;
		} else {
			return 0;
		}
	});
}