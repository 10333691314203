import { Alert, AlertTitle, Container } from "@mui/material";

/**
 * The alert type
 */
export enum AlertType {
	Error = "error",
	Warning = "warning",
	Info = "info",
	Success = "success",
}

type AlertBannerProps = {
	alertType: AlertType;
	header: string;
	message: string;
	details?: string;
};

/**
 * @param param0 the parameters as AlertBannerProps
 * @returns an alert banner
 */
const AlertBanner = ({
	alertType,
	header,
	message,
	details,
}: AlertBannerProps) => {
	return (
		<Container
			className="error-component"
			style={{ paddingTop: "2rem" }}
			data-testid="alert-banner"
		>
			<Alert variant="outlined" severity={alertType}>
				<AlertTitle>{header}</AlertTitle>
				{message}
				<div style={{ paddingTop: "1rem" }}>
					<span>{details}</span>
				</div>
			</Alert>
		</Container>
	);
};

export default AlertBanner;
