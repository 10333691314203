import { Box, Button, TextField } from "@mui/material";
import { FormEvent, useState } from "react";
import { useOrganizationMutator } from "../../api/organization";

// creates new organization
type OrganizationCreateProps = { createOrg: (orgName: string) => void };

/**
 * @returns the controls to create an organization
 */
const OrganizationCreate = ({ createOrg }: OrganizationCreateProps) => {
	const [name, setName] = useState("");
	const orgMutator = useOrganizationMutator();

	const createOrgClicked = async (e: FormEvent) => {
		e.preventDefault();
		const newOrg = await orgMutator.postOrganization(name);
		createOrg(newOrg.data);
		setName("");
	};

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", sm: "row" },
					pt: { xs: "1.3rem", sm: "2rem" },
					pb: { xs: 2, sm: 4 },
				}}
				gap="1em"
				component="form"
				onSubmit={createOrgClicked}
				data-testid="create-org"
			>
				<TextField
					fullWidth
					required
					label="Organization"
					value={name}
					onChange={(e) => setName(e.target.value)}
					data-testid="org-input"
				/>
				<Button
					variant="contained"
					color="primary"
					data-testid="create"
					disabled={!name}
					type="submit"
					sx={{ px: 4 }}
				>
					Create
				</Button>
			</Box>
		</>
	);
};

export default OrganizationCreate;
