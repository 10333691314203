import { Statistics } from "./api/api.generated";
const deepEqual = require("deep-equal");

// The measure name for memory total
export const MEMORY_TOTAL = "process.runtime.jvm.memory.max";
// The measure name for memory usage
export const MEMORY_IN_USE = "process.runtime.jvm.memory.usage";
// The measure attributes for heap memory
export const MEMORY_ATT_TYPE_HEAP = { type: "heap" };

// The measure name for disk space available
export const SPACE_AVAILABLE = "rhapsody.disk";
// The measure name for disk space total
export const SPACE_TOTAL = "rhapsody.disk.limit";
// The measure attributes for data drive disk space
export const SPACE_ATT_PATH_DATA = { "path.id": "DATA" };

// The measure name for CPU used
export const CPU_USED = "system.cpu.utilization";
// The measure attributes for CPU
export const CPU_ATT = {};

// The measure name for messages
export const MESSAGES_METRIC = "rhapsody.messages";
// The measure attributes for messages sent
export const MESSAGES_ATT_SENT = { type: "sent" };
// The measure attributes for messages received
export const MESSAGES_ATT_RECEIVED = { type: "received" };
// The measure attributes for messages processed
export const MESSAGES_ATT_PROCESSED = { type: "processed" };
// The measure attributes for messages failed
export const MESSAGES_ATT_FAILED = { type: "failed" };

// The measure name for queues
export const QUEUE_METRIC = "rhapsody.queue";
// The measure attributes for input queue
export const QUEUE_ATT_INPUT = { queue: "input" };
// The measure attributes for output queue
export const QUEUE_ATT_OUTPUT = { queue: "output" };
// The measure attributes for failed queue
export const QUEUE_ATT_FAILED = { queue: "failed" };
// The measure attributes for processing queue
export const QUEUE_ATT_PROCESSING = { queue: "processing" };

// The measure name for error queue count
export const ERROR_QUEUE_COUNT = "rhapsody.queue.error";
// The measure attribute for error queue
export const ERROR_QUEUE_ATT = {};

/**
 * Parses given stats from a statistics object
 *
 * @param statData the statistics
 * @param name the name of the measure
 * @param attributes the attributes of the measure
 * @returns the given stats
 */
export const ParseStatType = (
	statData: Statistics,
	name: string,
	attributes: { [key: string]: string }
): number[][] | null => {
	for (const entry of statData.data) {
		if (
			entry.n === name &&
			deepEqual(entry.a, attributes) &&
			entry.v.length > 0 &&
			entry.v[0].length > 1
		) {
			return entry.v;
		}
	}
	return null;
};

/**
 * The different severity colours
 */
export const enum SeverityColour {
	RED = "RED",
	ORANGE = "ORANGE",
	BLUE = "BLUE",
}

/**
 * Rhapsody event severity levels
 */
export enum EventSeverity {
	ESCALATION = "Escalation",
	ALARM = "Alarm",
	WARNING = "Warning",
}

// The opentelemetry severity value used for Rhapsody warnings
export const OPENTELEMETRY_WARN = 13;
// The opentelemetry severity value used for Rhapsody alarms
export const OPENTELEMETRY_ERROR = 17;
// The opentelemetry severity value used for Rhapsody escalations
export const OPENTELEMETRY_ERROR2 = 18;

/**
 * Maps the opentelemetry severity value to a colour.
 * Rhapsody sends 13 (WARN) for warnings, 17 (ERROR) for alarms and 18 (ERROR2) for escalations.
 * We map WARN to orange, and ERROR/ERROR2 to red.
 * See https://github.com/open-telemetry/opentelemetry-specification/blob/main/specification/logs/data-model.md#displaying-severity for the full list in OpenTelemetry.
 *
 * @param openTelemetrySeverity the open telemetry severity
 * @returns the mapped colour
 */
export const MapOpenTelemetrySeverityToColor = (
	openTelemetrySeverity: number
): SeverityColour => {
	if (openTelemetrySeverity >= OPENTELEMETRY_ERROR) {
		return SeverityColour.RED;
	}
	if (openTelemetrySeverity >= OPENTELEMETRY_WARN) {
		return SeverityColour.ORANGE;
	}
	return SeverityColour.BLUE;
};

/**
 * Maps the opentelemetry severity value to a Rhapsody event severity.
 * Refer to above function for more context on OpenTelemetry events and our usage.
 *
 * @param openTelemetrySeverity the open telemetry severity
 * @returns the mapped Rhapsody event severity
 */
export const MapOpenTelemetrySeverityToEventSeverity = (
	openTelemetrySeverity: number
): EventSeverity | null => {
	if (openTelemetrySeverity >= OPENTELEMETRY_ERROR2) {
		return EventSeverity.ESCALATION;
	}
	if (openTelemetrySeverity >= OPENTELEMETRY_ERROR) {
		return EventSeverity.ALARM;
	}
	if (openTelemetrySeverity >= OPENTELEMETRY_WARN) {
		return EventSeverity.WARNING;
	}
	return null;
};
