import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";

/**
 * @returns a typed dispatcher
 */
export const useAppDispatch = () => useDispatch<AppDispatch>();

/**
 * @returns a typed selector
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
