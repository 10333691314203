import { ReactComponent as EscalationIcon } from "../../assets/icons/escalation.svg";
import { ReactComponent as WarnIcon } from "../../assets/icons/warning.svg";
import { ReactComponent as AlarmIcon } from "../../assets/icons/alarm.svg";
import { EventSeverity } from "../../rhapsody-stats";

type EventSeverityIconProps = {
	severity: EventSeverity | null;
};

/**
 * Returns the corresponding icon for the event severity
 *
 * @param severity severity of the icon to return
 * @returns icon as a react component
 */
const EventIcons = ({ severity }: EventSeverityIconProps) => {
	if (severity === EventSeverity.ESCALATION) {
		return (
			<EscalationIcon
				style={{ paddingRight: "5px" }}
				data-testid={`events-icon-${severity}`}
			/>
		);
	}
	if (severity === EventSeverity.ALARM) {
		return (
			<AlarmIcon
				style={{ paddingRight: "5px" }}
				data-testid={`events-icon-${severity}`}
			/>
		);
	}
	if (severity === EventSeverity.WARNING) {
		return (
			<WarnIcon
				style={{ paddingRight: "5px" }}
				data-testid={`events-icon-${severity}`}
			/>
		);
	}
	return null;
};

export default EventIcons;
