import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import { FormEvent, useState } from "react";
import {
	useGetOrganizationTokens,
	useExistingOrganizationMutator,
	validTokenNameRegex,
} from "../../../../api/organization";
import TokenRow from "./token-row";

type TokensProps = {
	org: string;
};

/**
 * @param props the props as TokensProps
 * @returns the tokens of an organization on the manage page
 */
const Tokens = (props: TokensProps) => {
	const tokenMutator = useExistingOrganizationMutator(props.org);

	const tokens = useGetOrganizationTokens(props.org);
	const [createTokenDisabled, setCreateTokenDisabled] = useState(true);
	const [createTokenName, setCreateTokenName] = useState("");

	const submitToken = (e: FormEvent) => {
		e.preventDefault();
		setCreateTokenDisabled(true);
		const newName = createTokenName;
		setCreateTokenName("");
		tokenMutator.postToken(newName);
	};

	return (
		<Box>
			<Typography variant="h4" paddingBottom="1rem">
				Tokens:
			</Typography>
			<Box
				display="flex"
				alignItems="center"
				gap="1em"
				component="form"
				onSubmit={submitToken}
				paddingBottom="0.6rem"
			>
				<Box sx={{ width: "100%", flexGrow: { xs: 1, md: 0 } }}>
					<TextField
						size="small"
						sx={{ width: { xs: "100%", md: "35%" } }}
						value={createTokenName}
						onChange={(e) => {
							setCreateTokenName(e.target.value);
							setCreateTokenDisabled(
								!validTokenNameRegex.test(e.target.value)
							);
						}}
						label="Name"
						data-testid="token-create-value"
					/>
				</Box>
				<Button
					type="submit"
					disabled={createTokenDisabled}
					variant="contained"
					color="primary"
					data-testid="token-create-button"
					sx={{ width: "6rem" }}
				>
					CREATE
				</Button>
			</Box>
			<TableContainer sx={{ mt: 2 }}>
				<Table size="small">
					<TableHead
						sx={{
							display: { xs: "none", sm: "table-header-group" },
						}}
					>
						<TableRow>
							<TableCell
								sx={{
									display: { xs: "none", sm: "table-cell" },
								}}
							>
								<Typography variant="h6">Name</Typography>
							</TableCell>
							<TableCell
								sx={{
									display: { xs: "none", sm: "table-cell" },
								}}
							>
								<Typography variant="h6">Token</Typography>
							</TableCell>
							<TableCell
								sx={{
									display: { xs: "none", md: "table-cell" },
								}}
							>
								<Typography variant="h6">Engines</Typography>
							</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{tokens.map((t) => (
							<TokenRow token={t} org={props.org} key={t.token} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default Tokens;
