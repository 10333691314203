import { useGetOrganizationName } from "../../api/organization";

type OrganizationExistingHeaderProps = {
	org: string;
};

/**
 * @param props the parameters as OrganizationExistingHeaderProps
 * @returns the header for an organization on the manage page
 */
export const OrganizationExistingHeader = (
	props: OrganizationExistingHeaderProps
) => {
	const name = useGetOrganizationName(props.org);
	if (!name) {
		return <></>;
	}
	return <span data-testid={`button-${props.org}`}>{name}</span>;
};

export default OrganizationExistingHeader;
