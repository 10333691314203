import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TagsState {
	value: Array<string>;
}

const initialState: TagsState = {
	value: [],
};

/**
 * The slice to hold what tags we are currently filtering by
 */
const tagsSlice = createSlice({
	name: "tags",
	initialState: initialState,
	reducers: {
		setTags: (state, action: PayloadAction<Array<string>>) => {
			state.value = action.payload;
		},
	},
});

/**
 * A function that can be dispatched to change the current filter tags
 */
export const { setTags } = tagsSlice.actions;

/**
 * The reducer to add to the redux store
 */
export default tagsSlice.reducer;
