import {
	Box,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Skeleton,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import {
	Component,
	Components,
	Engine,
	Events as ApiEvents,
} from "../../api/api.generated";
import {
	useGetAllEngines,
	useGetComponents,
	useGetEvents,
} from "../../api/engine";
import { Clear } from "@mui/icons-material";
import { ChangeEvent, useEffect, useState } from "react";
import TreeTable from "./tree-table";
import Sunburst from "./sunburst";
import {
	ComponentType,
	GetComponentIcon,
	GetComponentType,
	GetDisplayString,
} from "./helpers";
import useDeepCompareEffect from "use-deep-compare-effect";

const defaultSelectedComponentTypes: ComponentType[] = ["ROUTE", "CP"];

enum ViewId {
	TREE = "tree",
	SUNBURST = "sunburst",
}

// The components in tree form
export type TreeComponent = {
	name: string;
	// The path
	id: string;
	// The size of the component in the sunburst. Leaf nodes are currently all sized the same (1) and folders/lockers have 0 size which means they just contain their children.
	value: number;
	// The colour, for the sunburst
	colour: "red" | "green";
	// The parsed type
	type: ComponentType;
	// Muxed in events
	events: ApiEvents;
	children: TreeComponent[];
};

const filterTreeComponentByTypes =
	(types: ComponentType[]) => (tc: TreeComponent) =>
		types.includes(tc.type);
const filterTreeComponentByName = (filter: string) => (tc: TreeComponent) =>
	tc.id.toLowerCase().includes(filter.toLowerCase());

const filterComponentTree = (
	treeComponents: TreeComponent[],
	filter: (tc: TreeComponent) => boolean
) => {
	const newTreeComponents: TreeComponent[] = [];
	treeComponents.forEach((c) => {
		// Name/folder filter
		if (!filter(c)) {
			const children = filterComponentTree(c.children, filter);
			if (children.length === 0) {
				return;
			}
			c.children = children;
		}
		newTreeComponents.push(c);
	});
	return newTreeComponents;
};

const addEventsToComponentTree = (
	components: Components,
	events: ApiEvents,
	result: TreeComponent
) => {
	for (const event of events) {
		const component = components.find((c) => c.id === event.component);
		result.colour = "red";
		if (component) {
			// Walk the tree
			let currentLevel: TreeComponent = result;
			(component.path + "/" + component.name)
				.split("/")
				.forEach((path, i, array) => {
					currentLevel = currentLevel.children.find(
						(c) => c.name === path
					)!;
					if (currentLevel) {
						currentLevel.colour = "red";
						if (i === array.length - 1) {
							currentLevel.events.push(event);
						}
					}
				});
		}
	}
};

const createItem = (
	i: number,
	pathAndName: string[],
	component: Component,
	path: string
): TreeComponent => {
	if (i === pathAndName.length - 1) {
		// Create the component and add it to the children
		return {
			name: component.name,
			id: pathAndName.join("/"),
			value: 1,
			type: GetComponentType(component),
			children: [],
			events: [],
			colour: "green",
		};
	} else {
		// Create a folder/locker and add it to the children
		return {
			name: path,
			id: pathAndName.slice(0, i + 1).join("/"),
			value: 0,
			type: i === 0 ? "LOCKER" : "FOLDER",
			children: [],
			events: [],
			colour: "green",
		};
	}
};

const createComponentTree = (
	components: Components,
	events: ApiEvents,
	engine?: Engine
) => {
	const result: TreeComponent = {
		name: engine?.name ?? "ROOT",
		id: "",
		value: 0,
		type: "ENGINE",
		children: [],
		events: [],
		colour: "green",
	};

	for (const component of components) {
		// Track where we currently are in the tree, starting at the dummy root node
		let currentLevel: TreeComponent = result;
		// Walk the tree
		(component.path + "/" + component.name)
			.split("/")
			.forEach((path, i, pathAndName) => {
				// See if we already have the path added
				let item = currentLevel.children.find((p) => p.name === path);
				if (!item) {
					// If its not added
					// Add the new item as a child
					item = createItem(i, pathAndName, component, path);
					currentLevel.children.push(item);
				}
				// Set it as the current folder and work down
				currentLevel = item;
			});
	}

	addEventsToComponentTree(components, events, result);

	return [result];
};

/**
 * WIP: behind IsRhapsodyEnabled feature flag
 *
 * @returns the components of the rhapsody configuration, displayed in multiple views
 */
const ComponentList = () => {
	// Retrieve the engine list
	const { engines, apiStatus: enginesApiStatus } = useGetAllEngines(
		(data, apiStatus) => {
			if (apiStatus.isSuccess && data) {
				return {
					engines: data,
					apiStatus,
				};
			}
			return { engineIds: [], apiStatus };
		}
	);
	const [selectedEngine, setSelectedEngine] = useState("");

	// Default to first engine when they have loaded
	useEffect(() => {
		if (selectedEngine === "" && engines && engines.length > 0) {
			setSelectedEngine(engines[0].id);
		}
	}, [engines, selectedEngine]);
	// Skip components/events calls until an engine is selected
	const skip = selectedEngine === "";

	const { components, apiStatus: componentsApiStatus } = useGetComponents(
		selectedEngine,
		(data, apiStatus) => {
			if (apiStatus.isSuccess && data) {
				return {
					components: data,
					apiStatus,
				};
			}
			return {
				components: [],
				apiStatus,
			};
		},
		skip
	);
	const { events, apiStatus: eventsApiStatus } = useGetEvents(
		selectedEngine,
		(data, apiStatus) => {
			if (apiStatus.isSuccess && data) {
				return {
					events: data,
					apiStatus,
				};
			}
			return { events: [], apiStatus };
		},
		skip
	);

	// Get out all the types.
	const allComponentTypes: ComponentType[] = [];
	components.forEach((t) => {
		const type = GetComponentType(t);
		if (!allComponentTypes.includes(type)) {
			allComponentTypes.push(type);
		}
	});

	// User filtering
	const [filter, setFilter] = useState<string>("");
	const [componentTypes, setComponentTypes] = useState<ComponentType[]>(
		defaultSelectedComponentTypes
	);

	// Build the complete component tree, and the filtered tree
	const fullComponentTree = createComponentTree(
		components,
		events,
		engines?.find((e) => e.id === selectedEngine)
	);
	const [treeComponents, setTreeComponents] = useState<TreeComponent[]>([]);
	useDeepCompareEffect(() => {
		const typeFilter = filterTreeComponentByTypes(componentTypes);
		const nameFilter = filterTreeComponentByName(filter);
		setTreeComponents(
			filterComponentTree(
				fullComponentTree,
				(c) => typeFilter(c) && nameFilter(c)
			)
		);
	}, [fullComponentTree, componentTypes, filter]);

	// User selections
	const [selectedView, setSelectedView] = useState<ViewId>(ViewId.TREE);
	const [selected, setSelected] = useState<TreeComponent | undefined>();

	return (
		<Box sx={{ height: "900px" }}>
			{enginesApiStatus.isLoading ||
			componentsApiStatus.isLoading ||
			eventsApiStatus.isLoading ? (
				<Skeleton variant="text" sx={{ fontSize: "2rem" }} />
			) : (
				<>
					<Stack direction="row" sx={{ marginTop: "20px" }}>
						<FormControl fullWidth>
							<InputLabel>Engine</InputLabel>
							<Select
								value={selectedEngine}
								onChange={(e) =>
									setSelectedEngine(e.target.value)
								}
								fullWidth
								label="Engine"
							>
								{engines?.map((c) => (
									<MenuItem key={c.id} value={c.id}>
										{c.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl>
							<InputLabel>View</InputLabel>
							<Select
								value={selectedView}
								onChange={(e) =>
									setSelectedView(e.target.value as ViewId)
								}
								label="View"
							>
								<MenuItem value={ViewId.TREE}>Tree</MenuItem>
								<MenuItem value={ViewId.SUNBURST}>
									Sunburst
								</MenuItem>
							</Select>
						</FormControl>
					</Stack>
					{selectedEngine !== "" && (
						<>
							<Box
								display="flex"
								flexDirection="row"
								alignItems="center"
							>
								<Box flexGrow={1}>
									<ToggleButtonGroup
										value={componentTypes}
										onChange={(
											_: any,
											newComponentTypes: ComponentType[]
										) =>
											setComponentTypes(newComponentTypes)
										}
									>
										{allComponentTypes.map((c) => (
											<ToggleButton key={c} value={c}>
												{GetComponentIcon(c)}
												<Typography>
													{GetDisplayString(c)}
												</Typography>
											</ToggleButton>
										))}
									</ToggleButtonGroup>
								</Box>

								<FormControl>
									<InputLabel htmlFor="filter-text-box">
										Filter
									</InputLabel>
									<OutlinedInput
										id="filter-text-box"
										label="Filter"
										value={filter}
										onChange={(
											e: ChangeEvent<HTMLInputElement>
										) => setFilter(e.target.value)}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													onClick={() =>
														setFilter("")
													}
													onMouseDown={(
														e: React.MouseEvent<HTMLButtonElement>
													) => e.preventDefault()}
													edge="end"
												>
													<Clear />
												</IconButton>
											</InputAdornment>
										}
									/>
								</FormControl>
							</Box>
							{selectedView === ViewId.TREE && (
								<TreeTable
									data={treeComponents}
									setSelected={setSelected}
								/>
							)}
							{selectedView === ViewId.SUNBURST && (
								<Sunburst
									data={treeComponents}
									setSelected={setSelected}
								/>
							)}

							{selected && (
								<Box key={selected.id}>
									<Typography>{selected.id}</Typography>
									{selected.events &&
										selected.events.map((e) => (
											<Box
												key={
													"selected-events-" + e.group
												}
											>
												<Typography>
													{e.timestamp}
												</Typography>
												<Typography>
													{e.eventType}
												</Typography>
												<Typography>
													{e.severity}
												</Typography>
											</Box>
										))}
								</Box>
							)}
						</>
					)}
				</>
			)}
		</Box>
	);
};

export default ComponentList;
