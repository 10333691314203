import React from "react";
import AlertBanner, { AlertType } from "../alert-banners/alert-banner";
import AlertMessage from "../alert-banners/alert-message";

type FallbackRenderer = (error: Error) => React.ReactNode;

interface ErrorBoundaryState {
	hasError: boolean;
	error?: Error;
	prevScope: string;
}

/**
 * The fallback type
 */
export enum FallbackType {
	AlertMessage = "AlertMessage",
	AlertBanner = "AlertBanner",
}

/**
 * The properties for an error boundary
 */
export interface ErrorBoundaryProps {
	fallback: React.ReactNode | FallbackRenderer | FallbackType;
	scope: string;
	message: string;
	styles?: React.CSSProperties;
	children: any;
}

/**
 * An error boundary element
 */
export class ErrorBoundary extends React.Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {
	static defaultProps = {
		scope: "error-boundary",
	};
	constructor(props: any) {
		super(props);
		this.state = {
			hasError: false,
			prevScope: props.scope,
		};
	}

	static getDerivedStateFromError = (error: Error) => {
		return { hasError: true, error };
	};

	static getDerivedStateFromProps(
		props: ErrorBoundaryProps,
		state: ErrorBoundaryState
	) {
		if (state.prevScope !== props.scope) {
			return {
				hasError: false,
				error: undefined,
				prevScope: props.scope,
			};
		}

		return state;
	}

	componentDidCatch = (error: Error, info: any) => {
		// @ts-ignore DD_RUM gets added from Datadog setup
		const RumGlobal = window.DD_RUM;
		if (RumGlobal) {
			RumGlobal.addError(
				error,
				{
					scope: this.props.scope,
				},
				"source"
			);
		}
	};

	render() {
		const { hasError } = this.state;
		const { fallback, message, styles } = this.props;
		if (!hasError) {
			return this.props.children;
		}

		// When an undefined or null has been thrown as an error the hasError is true but error is undefined
		let error = this.state.error;
		if (!error) {
			error = new Error("Undefined");
		}

		if (React.isValidElement(fallback)) {
			return fallback;
		} else if (typeof fallback === "function") {
			return fallback(error);
		} else if (typeof fallback === "string") {
			if (fallback === FallbackType.AlertMessage) {
				return (
					<AlertMessage
						alertType={AlertType.Error}
						message={`${message}: ${error.message}`}
						styles={styles}
					/>
				);
			} else if (fallback === FallbackType.AlertBanner) {
				return (
					<AlertBanner
						alertType={AlertType.Error}
						header={message}
						message={error.message}
						details={error.stack}
					/>
				);
			}
		}

		return null;
	}
}
