import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import EngineAccordion from "./accordion/engine-accordion";
import EngineTable from "./table/engine-table";

type EnginesProps = {
	org: string;
};

/**
 * @param props the parameters as EngineProps
 * @returns the engines on the manage page
 */
const Engines = (props: EnginesProps) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Box>
			<Typography variant="h4">Engines:</Typography>
			<Box
				data-testid="engine-table"
				display={mobile === true ? "none" : "block"}
			>
				<EngineTable org={props.org} />
			</Box>
			<Box
				data-testid="engine-accordion"
				display={mobile === true ? "block" : "none"}
			>
				<EngineAccordion org={props.org} />
			</Box>
		</Box>
	);
};

export default Engines;
