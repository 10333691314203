import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Manage from "./features/manage/manage";
import EngineTiles from "./features/dashboard/engine-tiles";
import Header from "./features/header/header";
import ComponentList from "./features/rhapsody/component-list";
import { SetupDatadog } from "./datadog/datadog-setup";
import { useAppSelector } from "./app/hooks";
import { useGetCurrentUser } from "./api/user";
import Login from "./components/login/login";
import { IsRhapsodyEnabled } from "./utils/feature-flags";
import { Box, Container } from "@mui/material";

/**
 * @returns our application
 */
const App = () => {
	if (process.env.NODE_ENV === "production") {
		SetupDatadog();
	}
	const { user, apiStatus } = useGetCurrentUser();
	const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

	// Just display header until we know whether we are logged in or not
	if (apiStatus.isLoading) {
		return (
			<div>
				<Header />
			</div>
		);
	}

	if (!isLoggedIn) {
		return (
			<div>
				<Header />
				<Login />
			</div>
		);
	}

	return (
		<div>
			<Header currentUser={user} />
			<Box data-testid="home-page">
				<Box color="black" minHeight="100%" py="3">
					<Container maxWidth={false}>
						<BrowserRouter>
							<Routes>
								{IsRhapsodyEnabled && (
									<Route
										path="/rhapsody"
										element={<ComponentList />}
									/>
								)}
								<Route path="/manage" element={<Manage />} />
								<Route path="/" element={<EngineTiles />} />
							</Routes>
						</BrowserRouter>
					</Container>
				</Box>
			</Box>
		</div>
	);
};

export default App;
