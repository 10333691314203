import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

/**
 * Shows a snack in the snackbar based on the result object returned from the API
 *
 * @param enqueueSnackbar the function to post a snack
 * @param result the result of the api call
 * @param successMessage the snack message to display if the api call was successful
 * @param errorMessage the snack message to display if the api call was unsuccessful
 */
export const showSnackWithResponse = (
	enqueueSnackbar: (
		message: SnackbarMessage,
		options?: OptionsObject | undefined
	) => SnackbarKey,
	result: { data?: any; error?: any },
	successMessage: string,
	errorMessage: string
) => {
	if ("error" in result) {
		enqueueSnackbar(errorMessage, {
			variant: "error",
		});
	} else {
		enqueueSnackbar(successMessage, {
			variant: "success",
		});
	}
};
