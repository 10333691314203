import { Alert, Container } from "@mui/material";
import { AlertType } from "./alert-banner";

type AlertMessageProps = {
	alertType: AlertType;
	message: string;
	styles?: React.CSSProperties;
};

/**
 * @param param0 the parameters as AlertMessageProps
 * @returns an alert message
 */
const AlertMessage = ({ alertType, message, styles }: AlertMessageProps) => {
	return (
		<Container
			style={styles}
			className="error-component"
			data-testid="alert-message"
		>
			<Alert severity={alertType}>{message}</Alert>
		</Container>
	);
};

export default AlertMessage;
