import { EventSeverity } from "../../rhapsody-stats";
import EventIcons from "./event-icons";

type EngineEventsProps = {
	severity: EventSeverity;
	count: number;
};

/**
 * Event count by severity with corresponding icon.
 *
 * @param param0 the parameters as EngineEventProps
 * @returns the event list for a given engine id
 */
const EngineEvents = ({ severity, count }: EngineEventsProps) => {
	return (
		<>
			{<EventIcons severity={severity} />}
			<span data-testid={`engine-events-count-${severity}`}>{count}</span>
		</>
	);
};

export default EngineEvents;
