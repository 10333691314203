import { Typography, CardHeader, Box, Link } from "@mui/material";
import { Launch } from "@mui/icons-material";

import "./engine-tile.scss";
import { useGetEvents, useGetLatestStatistic } from "../../../api/engine";
import { getColour, getTileData } from "./engine-stats";
import { Events, Statistics } from "../../../api/api.generated";
import {
	MapOpenTelemetrySeverityToColor,
	SeverityColour,
} from "../../../rhapsody-stats";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
	getEngineSeverity,
	addEngineSeverity,
	updateEngineSeverity,
} from "../../../app/redux/engine-severity-slice";
import { useEffect } from "react";
import { calculateSeverity } from "./engine-severity-helper";

// The engine colours
export type EngineColour = "red" | "orange" | "green";

const calculateColour = (...colours: EngineColour[]): EngineColour => {
	// If there are any RED events, or any resource is RED (i.e. usage is dangerously high) then health is RED.
	if (colours.includes("red")) {
		return "red";
	}
	//Otherwise, if there are any ORANGE events, or any resource is ORANGE (i.e. usage is moderately high), then health is ORANGE.
	if (colours.includes("orange")) {
		return "orange";
	}
	//Otherwise health is GREEN.
	return "green";
};

const calculateEventColour = (events: Events): EngineColour => {
	const highestEventSeverity = events.reduce(
		(prev, current) => (prev > current.severity ? prev : current.severity),
		0
	);
	const color = MapOpenTelemetrySeverityToColor(highestEventSeverity);

	// If there are any RED events, or any resource is RED (i.e. usage is dangerously high) then health is RED.
	if (color === SeverityColour.RED) {
		return "red";
	}
	//Otherwise, if there are any ORANGE events, or any resource is ORANGE (i.e. usage is moderately high), then health is ORANGE.
	if (color === SeverityColour.ORANGE) {
		return "orange";
	}
	//Otherwise health is GREEN.
	return "green";
};

const calculateStatColour = (stats?: Statistics): EngineColour => {
	const tileData = getTileData(stats);

	// If there any resource is RED (i.e. usage is dangerously high) then health is RED.
	if (tileData.find((s) => getColour(s) === "red")) {
		return "red";
	}

	//Otherwise, if there are any resource is ORANGE (i.e. usage is moderately high), then health is ORANGE.
	if (tileData.find((s) => getColour(s) === "orange")) {
		return "orange";
	}

	//Otherwise health is GREEN.
	return "green";
};

/**
 * The properties for an engine card
 */
export type EngineCardHeaderProps = {
	id: string;
	name: string;
	link: string;
	isConnected: boolean;
};

/**
 * @param param0 the parameters as EngineCardHeaderProps
 * @returns the header for an engine card
 */
const EngineCardHeader = ({
	id,
	name,
	link,
	isConnected,
}: EngineCardHeaderProps) => {
	const dispatch = useAppDispatch();

	const { eventColour } = useGetEvents(id, (data, apiStatus) => {
		if (apiStatus.isSuccess && data) {
			return {
				eventColour: calculateEventColour(data),
			};
		}
		return { eventColour: calculateEventColour([]) };
	});

	const { statColour } = useGetLatestStatistic(id, (data, apiStatus) => {
		if (apiStatus.isSuccess && data) {
			return {
				statColour: calculateStatColour(data),
			};
		}
		return { statColour: calculateStatColour() };
	});

	const stripeColour = isConnected
		? calculateColour(statColour, eventColour)
		: "";

	const severity = calculateSeverity(isConnected, stripeColour);

	const engineSeverity = useAppSelector((state) =>
		getEngineSeverity(state, id)
	);

	useEffect(() => {
		if (!engineSeverity) {
			dispatch(addEngineSeverity({ id: id, severity: severity }));
		} else {
			dispatch(updateEngineSeverity({ id: id, severity: severity }));
		}
	});

	return (
		<>
			<Box
				className={`${stripeColour.toString()} card-header-stripe`}
				data-testid="card-status"
			/>
			<CardHeader
				className="card-root"
				data-testid={"card-root"}
				title={
					<Box display="flex" flexDirection="row" alignItems="center">
						<Box flexGrow={1} data-testid={"engine-name-" + id}>
							<Typography variant="h4">{name}</Typography>
						</Box>
						{link && (
							<Link
								href={link}
								target="_blank"
								rel="noreferrer"
								underline="none"
								variant="body2"
								className="header-link"
								data-testid="details-link"
							>
								<span>Management Console</span>
								<Launch style={{ paddingLeft: "0.2em" }} />
							</Link>
						)}
					</Box>
				}
			/>
		</>
	);
};

export default EngineCardHeader;
