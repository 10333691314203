import { Launch, DeleteOutline } from "@mui/icons-material";
import {
	Box,
	IconButton,
	Link,
	TableCell,
	TableRow,
	Chip,
} from "@mui/material";
import { useState } from "react";
import { Engine } from "../../../../../api/api.generated";
import { useExistingEngineMutator } from "../../../../../api/engine";
import { useOkCancelDialog } from "../../../../../providers/ok-cancel-dialog/ok-cancel-dialog-provider";
import { timeAgo } from "../../../../../utils/duration-util";

const confirmDeleteTitle = "Are you sure you want to delete this engine?";
const confirmDeleteContent =
	"Deleting this engine will delete all of its historic data. Do you want to continue?";

type EngineRowProps = { engine: Engine };

/**
 * @param props the props as EngineRowProps
 * @returns an engine row on the manage page
 */
const EngineRow = (props: EngineRowProps) => {
	const { openDialog } = useOkCancelDialog();
	const existingEngineMutator = useExistingEngineMutator(props.engine.id);

	const [deleteEngineDisabled, setDeleteEngineDisabled] = useState(false);

	const performDelete = () => {
		setDeleteEngineDisabled(true);
		existingEngineMutator.deleteEngine();
	};

	return (
		<TableRow data-testid={"engine-name-" + props.engine.name}>
			<TableCell>
				<Box
					width="90%"
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="space-between"
					data-testid="engine-name"
				>
					<Box>{props.engine.name}</Box>
					{props.engine.link && (
						<Link
							href={props.engine.link}
							target="_blank"
							rel="noreferrer"
							underline="none"
							variant="body2"
							className="header-link"
						>
							<Launch style={{ paddingLeft: "0.2em" }} />
						</Link>
					)}
				</Box>
			</TableCell>
			<TableCell data-testid="engine-version">
				{props.engine.version}
			</TableCell>
			<TableCell data-testid="engine-tags">
				{props.engine.tags.map((tag) => (
					<Chip
						key={tag}
						label={tag}
						color="primary"
						sx={{ mr: 1, my: 0.5 }}
					/>
				))}
			</TableCell>
			<TableCell data-testid="lastUpdate">
				{props.engine.lastUpdate
					? timeAgo(props.engine.lastUpdate) + " ago"
					: "Never"}
			</TableCell>
			<TableCell align="right" data-testid="del-engine">
				<IconButton
					data-testid={"del-engine-" + props.engine.name}
					color="primary"
					size="large"
					onClick={async () => {
						const dialogResult = await openDialog(
							confirmDeleteTitle,
							confirmDeleteContent
						);
						if (dialogResult) {
							performDelete();
						}
					}}
					disabled={deleteEngineDisabled}
				>
					<DeleteOutline fontSize="inherit" />
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default EngineRow;
