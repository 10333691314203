import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";

import "./index.scss";
import App from "./App";
import theme from "./styles/theme";
import { store } from "./app/store";
import {
	ErrorBoundary,
	FallbackType,
} from "./components/error-handling/error-boundary";
import { OkCancelDialogProvider } from "./providers/ok-cancel-dialog/ok-cancel-dialog-provider";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<Provider store={store}>
					<SnackbarProvider maxSnack={3}>
						<OkCancelDialogProvider>
							<ErrorBoundary
								scope="Console App"
								fallback={FallbackType.AlertBanner}
								message="There has been an unhandled error"
								styles={{ padding: 0 }}
							>
								<App />
							</ErrorBoundary>
						</OkCancelDialogProvider>
					</SnackbarProvider>
				</Provider>
			</ThemeProvider>
		</StyledEngineProvider>
	</React.StrictMode>
);
