import { Button, Container } from "@mui/material";
import { GetBaseUrl } from "../../api/api.customBaseQuery";

/**
 * @returns the login screen
 */
const Login = () => {
	const loginClicked = () => {
		window.location.assign(
			GetBaseUrl() + "/auth/login?redirect=" + window.location.href
		);
	};

	return (
		<Container maxWidth="xs">
			<Button
				fullWidth
				variant="contained"
				sx={{
					marginTop: 8,
					alignItems: "center",
				}}
				id="login-button"
				onClick={loginClicked}
			>
				Sign In
			</Button>
		</Container>
	);
};

export default Login;
