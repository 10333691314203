import { useGetAllTags } from "../../../api/tag";
import { Autocomplete, TextField } from "@mui/material";
import { useAppDispatch } from "../../../app/hooks";
import { setTags } from "../../../app/redux/tags-slice";

/**
 * @returns the tag selector
 */
const Tags = () => {
	const { allTags, apiStatus } = useGetAllTags();
	const dispatch = useAppDispatch();

	return (
		<Autocomplete
			multiple
			options={allTags}
			filterSelectedOptions
			data-testid="tags"
			data-test-is-fetching={apiStatus.isFetching}
			renderInput={(params) => (
				<TextField variant="outlined" {...params} label="Tags" />
			)}
			onChange={(_: object, value: string[]) => {
				dispatch(setTags(value));
			}}
			ChipProps={{
				color: "primary",
			}}
		/>
	);
};

export default Tags;
