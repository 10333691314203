import {
	Bolt,
	Dashboard,
	ExitToApp,
	KeyboardArrowDown,
	Menu as MenuIcon,
	Settings,
} from "@mui/icons-material";
import {
	AppBar,
	Box,
	Button,
	Drawer,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
} from "@mui/material";
import { useState } from "react";
import { GetDisplayValue } from "../../api/user";
import { ReactComponent as Logo } from "../../assets/icons/lyniate.svg";
import { GetBaseUrl } from "../../api/api.customBaseQuery";
import { User } from "../../api/api.generated";
import { IsMobile } from "../../utils/responsive-util";
import { IsRhapsodyEnabled } from "../../utils/feature-flags";

type HeaderProps = {
	currentUser?: User;
};

/**
 * @param param0 the parameters as HeaderProps
 * @returns the page header
 */
const Header = ({ currentUser }: HeaderProps) => {
	const displayName = currentUser ? GetDisplayValue(currentUser) : null;
	const isMobile = IsMobile();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [drawerOpen, setDrawerOpen] = useState(false);

	const logoutUrl = GetBaseUrl() + "/auth/logout";

	return (
		<Box flexGrow="1">
			<AppBar position="sticky">
				<Toolbar>
					{displayName && (
						<IconButton
							size="large"
							edge="start"
							sx={{ mr: 2, color: "white" }}
							onClick={() => setDrawerOpen(true)}
						>
							<MenuIcon />
						</IconButton>
					)}
					<Box
						alignItems="center"
						display="flex"
						flexGrow="1"
						component={Link}
						href="/"
						underline="hover"
						data-testid="home-link"
					>
						<Box
							component={Logo}
							sx={{
								width: 148,
								display: { xs: "none", sm: "block" },
							}}
						/>
						<Typography
							variant="h3"
							style={{
								fontSize: 24,
								letterSpacing: 1,
								color: "#000",
							}}
						>
							CONSOLE
						</Typography>
					</Box>
					{displayName && (
						<div>
							<Button
								color="inherit"
								id="user-button"
								aria-controls="user-menu"
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
								onClick={handleClick}
								endIcon={<KeyboardArrowDown />}
								data-testid="user-menu"
								sx={{
									display: {
										maxWidth: isMobile ? "160px" : "none",
									},
								}}
							>
								<span
									style={{
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{displayName}
								</span>
							</Button>
							<Menu
								id="user-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									"aria-labelledby": "user-button",
								}}
							>
								<MenuItem
									component={Link}
									href="/manage"
									underline="hover"
									data-testid="menu-item-manage"
								>
									<ListItemIcon>
										<Settings fontSize="small" />
									</ListItemIcon>
									<ListItemText>Manage</ListItemText>
								</MenuItem>
								<MenuItem
									component={Link}
									href={logoutUrl}
									underline="hover"
									data-testid="menu-item-logout"
								>
									<ListItemIcon>
										<ExitToApp fontSize="small" />
									</ListItemIcon>
									<ListItemText>Logout</ListItemText>
								</MenuItem>
							</Menu>
						</div>
					)}
				</Toolbar>
			</AppBar>
			{displayName && (
				<Drawer
					anchor="left"
					open={drawerOpen}
					onClose={() => setDrawerOpen(false)}
				>
					<List>
						<ListItem component={Link} href="/">
							<ListItemButton>
								<ListItemIcon>
									<Dashboard />
								</ListItemIcon>
								<ListItemText>Cards</ListItemText>
							</ListItemButton>
						</ListItem>
						{IsRhapsodyEnabled && (
							<ListItem component={Link} href="/rhapsody">
								<ListItemButton>
									<ListItemIcon>
										<Bolt />
									</ListItemIcon>
									<ListItemText>Rhapsody</ListItemText>
								</ListItemButton>
							</ListItem>
						)}
					</List>
				</Drawer>
			)}
		</Box>
	);
};

export default Header;
