import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
	isLoggedIn: boolean;
}

const initialState: AuthState = {
	isLoggedIn: true,
};

/**
 * The slice to handle whether we are logged in across the application
 */
const authSlice = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {
		setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
			state.isLoggedIn = action.payload;
		},
	},
});

/**
 * A function that can be dispatched to change whether we are logged in
 */
export const { setIsLoggedIn } = authSlice.actions;

/**
 * The reducer to add to the redux store
 */
export default authSlice.reducer;
