import {
	Components,
	Engine,
	Engines,
	Events,
	Statistics,
	useDeleteEngineMutation,
	useGetComponentsQuery,
	useGetCurrentStatisticsQuery,
	useGetEngineQuery,
	useGetLatestEventsQuery,
	useGetStatisticsQuery,
	useGetUptimeQuery,
} from "./api.generated";
import { showSnackWithResponse } from "./mutationSnackbarHelper";

import { useAppSelector } from "../app/hooks";
import { useMemoSelector } from "./memoSelector";
import * as Stats from "../rhapsody-stats";
import { ApiStatus, UptimeEnhanced } from "./api.enhanced";
import { useSnackbar } from "notistack";

// The toast message when engine delete is successful
export const DeleteEngineSuccessMessage = "Deleted engine";
// The toast message when engine delete fails
export const DeleteEngineFailureMessage = "Failed to delete engine";

// Polling intervals in ms
const engineApiPollingInterval = 20000;
const eventsApiPollingInterval = 20000;
const statisticsApiPollingInterval = 20000;
const uptimeApiPollingInterval = 20000;

/**
 * A wrapper around the get all engine API call that executes a given function to filter the data
 *
 * @param fn the function to filter the data
 * @param organizationId the organizationId the engines need to belong to, or undefined for all organizations
 * @returns the engine details for the given organization (or all), filtered by the function
 */
export const useGetAllEngines = <R extends Record<string, any>>(
	fn: (foundEngines: Engines | undefined, apiStatus: ApiStatus) => R,
	organizationId?: string
): R => {
	const selector = useMemoSelector(fn, "getAllEngines");
	return useGetEngineQuery(
		{
			tag: useAppSelector((state) => state.tags.value),
			organizationId,
		},
		{
			pollingInterval: engineApiPollingInterval,
			selectFromResult: (res) => selector(res.data, res),
		}
	);
};

/**
 * A wrapper around the get all engine API call that filters by an engine and then executes a given function to further filter the data
 *
 * @param engineId the engine id
 * @param fn the function to filter the data
 * @returns the engine details for the given engine, filtered by the function
 */
export const useGetEngine = <R extends Record<string, any>>(
	engineId: string,
	fn: (foundEngine: Engine | undefined, apiStatus: ApiStatus) => R
): R => {
	return useGetAllEngines((data, apiStatus) => {
		const foundEngine = data?.find((e) => e.id === engineId);
		return fn(foundEngine, apiStatus);
	});
};

/**
 * A wrapper around the events API call that executes a given function to further filter the data
 *
 * @param engineId the engine id
 * @param fn the function to filter the data
 * @param skip whether to skip the call
 * @returns the events for a given engine, filtered by the function. Or skipped if skip is true.
 */
export const useGetEvents = <R extends Record<string, any>>(
	engineId: string,
	fn: (data: Events | undefined, apiStatus: ApiStatus) => R,
	skip?: boolean
): R => {
	const selector = useMemoSelector(fn, "getEvents");
	return useGetLatestEventsQuery(
		{
			engine: engineId,
			existingFields: ["state"],
			matchingFields: ["state", "ACTIVE"],
		},
		{
			pollingInterval: eventsApiPollingInterval,
			selectFromResult: (res) => selector(res.data, res),
			skip,
		}
	);
};

/**
 * A wrapper around the statistics API call that executes a given function to further filter the data
 *
 * @param engineId the engine id
 * @param from the from time
 * @param duration  the duration
 * @param measures the measures
 * @param interpolationResolution the interpolation resolution
 * @param fn the function to filter the data
 * @returns the statistics for the given engine, from, duration, measure and interpolation resolution, filtered by the function
 */
export const useGetStatistics = <R extends Record<string, any>>(
	engineId: string,
	from: number,
	duration: number,
	measures: string[],
	interpolationResolution: number,
	fn: (data: Statistics | undefined, apiStatus: ApiStatus) => R
): R => {
	const selector = useMemoSelector(fn, "getStatistics");
	return useGetStatisticsQuery(
		{
			engine: engineId,
			component: "ROOT",
			measures,
			from,
			duration,
			per: 60,
			interpolationResolution,
		},
		{
			pollingInterval: statisticsApiPollingInterval,
			selectFromResult: (res) => selector(res.data, res),
		}
	);
};

/**
 * A wrapper around the latest statistic API call that executes a given function to further filter the data
 *
 * @param engineId the engine id
 * @param fn the function to filter the data
 * @param measures the measures, or by default the cpu/memory/space measures
 * @param components the components, or by default the ROOT component
 * @returns the latest statistics for the given engine, measure and component, filtered by the function
 */
export const useGetLatestStatistic = <R extends Record<string, any>>(
	engineId: string,
	fn: (data: Statistics | undefined, apiStatus: ApiStatus) => R,
	measures?: string[],
	components?: string[]
): R => {
	const selector = useMemoSelector(fn, "getLatestStatistic");
	return useGetCurrentStatisticsQuery(
		{
			engine: engineId,
			components: components ?? ["ROOT"],
			measures: measures ?? [
				Stats.CPU_USED,
				Stats.MEMORY_IN_USE,
				Stats.MEMORY_TOTAL,
				Stats.SPACE_AVAILABLE,
				Stats.SPACE_TOTAL,
				Stats.ERROR_QUEUE_COUNT,
			],
			maxAge: 60 * 6, // Ensure that this is greater than the offline time so we will show graphs even if we aren't showing we are offline yet.
		},
		{
			pollingInterval: statisticsApiPollingInterval,
			selectFromResult: (res) => selector(res.data, res),
		}
	);
};

/**
 * A wrapper around the uptime API call that executes a given function to further filter the data.
 *
 * @param engineId the engine id
 * @param fn the function to filter the data
 * @returns the uptime value for the given engine, filtered by the function
 */
export const useGetUptime = <R extends Record<string, any>>(
	engineId: string,
	fn: (data: UptimeEnhanced | undefined, apiStatus: ApiStatus) => R
): R & { fulfilledTimeStamp?: number } => {
	const selector = useMemoSelector(fn, "getUptime");

	return useGetUptimeQuery(
		{
			engine: engineId,
			duration: 30 * 24 * 3600,
		},
		{
			pollingInterval: uptimeApiPollingInterval,
			selectFromResult: (res) => selector(res.data, res),
		}
	);
};

/**
 * A wrapper around the existing engine mutators.
 *
 * @param id the engine id
 * @returns the mutator to delete an engine
 */
export const useExistingEngineMutator = (id: string) => {
	const [deleteEngine] = useDeleteEngineMutation();
	const { enqueueSnackbar } = useSnackbar();

	return {
		deleteEngine: async () => {
			const result = await deleteEngine({ engine: id });
			showSnackWithResponse(
				enqueueSnackbar,
				result,
				DeleteEngineSuccessMessage,
				DeleteEngineFailureMessage
			);
		},
	};
};

/**
 * A wrapper around the components API call.
 *
 * @param engineId the engine id
 * @param fn the function to filter the data
 * @param skip whether to actually execute this query
 * @returns the component list for a given engine
 */
export const useGetComponents = <R extends Record<string, any>>(
	engineId: string,
	fn: (data: Components | undefined, apiStatus: ApiStatus) => R,
	skip?: boolean
) => {
	const selector = useMemoSelector(fn, "getComponents");

	return useGetComponentsQuery(
		{ engine: engineId },
		{
			pollingInterval: eventsApiPollingInterval,
			selectFromResult: (res) => selector(res.data, res),
			skip,
		}
	);
};
