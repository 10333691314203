import { configureStore } from "@reduxjs/toolkit";
import tagsReducer from "./redux/tags-slice";
import authReducer from "./redux/auth-slice";
import engineSeverityReducer from "./redux/engine-severity-slice";
import { enhancedApi } from "../api/api.enhanced";

/**
 * The redux store
 */
export const store = configureStore({
	reducer: {
		[enhancedApi.reducerPath]: enhancedApi.reducer,
		tags: tagsReducer,
		auth: authReducer,
		engineSeverity: engineSeverityReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(enhancedApi.middleware),
});

/**
 * The typing for the store
 */
export type RootState = ReturnType<typeof store.getState>;

/**
 * The typing for the dispatch
 */
export type AppDispatch = typeof store.dispatch;
