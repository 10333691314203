// Max value of a this function is 9,007,199,254,740,991 (2^53 - 1)

const units = ["B", "KB", "MB", "GB", "TB", "PB"];
const unitsCount = units.length;

// Select trailing 0s after a decimal point, including the decimal point if all the trailing digits are 0s.
// Capturing groups are as follows:
// (1) Decimal point followed by all 0s
// (2) Decimal point followed by non-zero digits maybe followed by trailing 0s
// (3) Decimal point with no trailing 0s
// (4) Trailing 0s
const trailingZeros = /(\.0*$)|((\..*?)(0*)$)/;

/**
 * Converts a given bytes into a human readable format
 *
 * @param bytes the number of bytes
 * @param delimiter the delimiter, or a space by default
 * @returns a human readable string
 */
export const HumanizeBytes = (bytes: number, delimiter = " ") => {
	// Convert to memory units.
	let i = 0;
	let n = bytes;
	while (n >= 1024 && i < unitsCount) {
		n = n / 1024;
		i += 1;
	}

	return (
		(n >= 1000
			? n.toFixed(0)
			: n.toPrecision(3).replace(trailingZeros, "$3")) +
		delimiter +
		units[i]
	);
};
