import { Box } from "@mui/material";
import { useGetAllEngines } from "../../../../../api/engine";
import EngineAccordionRow from "./engine-accordion-row";

type EngineTableProps = {
	org: string;
};

/**
 * @param props the parameters as EngineProps
 * @returns the engines on the manage page
 */

const EngineTable = (props: EngineTableProps) => {
	const { engines, apiStatus } = useGetAllEngines((data, apiStatus) => {
		if (apiStatus.isSuccess && data) {
			return { engines: data, apiStatus };
		}
		return { engines: [], apiStatus };
	}, props.org);

	return (
		<Box
			data-testid="engines-list"
			data-test-is-fetching={apiStatus.isFetching}
		>
			{engines.map((e) => (
				<EngineAccordionRow key={e.id} engine={e} />
			))}
		</Box>
	);
};

export default EngineTable;
