import { IconButton, Input, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { Token } from "../../../../api/api.generated";

// User can copy the token when visibility is toggled

const placeholderString = "●".repeat(36);
type TokenVisibilityProps = { org: string; token: Token };

/**
 * @param props the parameters as TokenVisibilityProps
 * @returns a token row on the manage page
 */
const TokenVisibility = (props: TokenVisibilityProps) => {
	const [showValue, setShowValue] = useState(false);

	return (
		<Input
			type="text"
			value={showValue ? props.token.token : placeholderString}
			sx={{ width: "90%" }}
			disabled={true}
			endAdornment={
				<InputAdornment position="end">
					<IconButton
						aria-label="toggle token visibility"
						size="small"
						onClick={(_) => setShowValue(!showValue)}
						onMouseDown={(e) => e.preventDefault()}
						edge="end"
					>
						{showValue ? <Visibility /> : <VisibilityOff />}
					</IconButton>
				</InputAdornment>
			}
		/>
	);
};

export default TokenVisibility;
