import { CircleLoader } from "react-spinners";
import "./loader-spinner.scss";

/**
 * The size of the spinner
 */
export enum SpinnerSize {
	small = "30px",
	medium = "50px",
	large = "80px",
}

type Props = {
	spinnerSize: SpinnerSize;
};

/**
 * @param param0 the parameters as Props
 * @returns a loading spinner
 */
const Loading = ({ spinnerSize }: Props) => {
	return (
		<div
			style={{
				display: "flex",
				flexFlow: "column",
				paddingTop: "10%",
			}}
			data-testid="loading-spinner"
			className="loading-spinner"
		>
			<CircleLoader
				color="#1a81f4"
				size={spinnerSize}
				cssOverride={{
					margin: "auto",
				}}
			/>
			<div
				style={{
					margin: "auto",
					paddingTop: "1rem",
				}}
			>
				Loading ...
			</div>
		</div>
	);
};

export default Loading;
