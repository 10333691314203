import { Box, Grid } from "@mui/material";
import EngineTile from "./engine-tile/engine-tile";
import Tags from "./tags/tags";
import { useGetAllEngines } from "../../api/engine";
import Loading, { SpinnerSize } from "../../components/loaders/loader-spinner";
import AlertBanner, {
	AlertType,
} from "../../components/alert-banners/alert-banner";
import { ApiStatus } from "../../api/api.enhanced";
import AlertMessage from "../../components/alert-banners/alert-message";
import {
	ErrorBoundary,
	FallbackType,
} from "../../components/error-handling/error-boundary";
import { memo, useState } from "react";
import SortBy from "./sort-by/sort-by";
import useDeepCompareEffect from "use-deep-compare-effect";
import { SortByType } from "./sort-by/sort-by-type";
import { Engine } from "../../api/api.generated";
import { useAppSelector } from "../../app/hooks";
import {
	EngineSeverity,
	getEngineSeverities,
} from "../../app/redux/engine-severity-slice";
import { useLocalStore } from "../../utils/storage-util";

const deepEqual = require("deep-equal");

const EngineTileMemo = memo(EngineTile);

const loadTile = (id: string) => {
	return (
		<Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={"engine-" + id}>
			<ErrorBoundary
				scope={"Loading engine"}
				fallback={FallbackType.AlertMessage}
				message={`Error loading engine '${id}'`}
				styles={{ marginTop: "1em", padding: 0 }}
			>
				<EngineTileMemo id={id} key={"engine-tile-" + id} />
			</ErrorBoundary>
		</Grid>
	);
};

const loadEngines = (engines: Engine[]) => {
	if (engines.length === 0) {
		return (
			<Box
				style={{ paddingTop: "1rem", display: "flex" }}
				data-testid="no-engines"
			>
				<AlertMessage alertType={AlertType.Info} message="No engines" />
			</Box>
		);
	} else if (engines.length > 0) {
		return (
			<Box data-testid="engines">
				<Grid
					container
					spacing={3}
					className="stats-pane"
					data-testid="engines-list"
				>
					{engines.map((engine) => loadTile(engine.id))}
				</Grid>
			</Box>
		);
	}
};
const loadFilterPane = (
	sortCriterion: SortByType,
	setSortByType: (type: string) => void
) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={8}>
				<Box style={{ paddingTop: "1rem" }}>
					<Tags />
				</Box>
			</Grid>
			<Grid item xs={12} sm={4}>
				<Box style={{ paddingTop: "1rem" }}>
					<SortBy
						sortByType={sortCriterion}
						setSortByType={setSortByType}
					/>
				</Box>
			</Grid>
		</Grid>
	);
};
const loadComponent = (
	apiStatus: ApiStatus,
	engines: Engine[],
	sortCriterion: SortByType,
	setSortByType: (type: string) => void
) => {
	if (apiStatus.isError) {
		return (
			<AlertBanner
				alertType={AlertType.Error}
				header="Error"
				message="Error loading the engines, try reloading the page."
				details={JSON.stringify(apiStatus.error)}
			/>
		);
	}
	if (apiStatus.isLoading) {
		return <Loading spinnerSize={SpinnerSize.large} />;
	}
	if (!apiStatus.isLoading && !apiStatus.isError) {
		return (
			<>
				{loadFilterPane(sortCriterion, setSortByType)}
				{loadEngines(engines)}
			</>
		);
	}
};

/**
 * sortEnginesBySeverity sorts the engines array by sortedSeverities then name.
 * @param engines are mutated during the sort
 * @param sortedSeverities sorted in the order of severity
 */
const sortEnginesBySeverity = (
	engines: Engine[],
	sortedSeverities: EngineSeverity[]
) => {
	engines.sort((a, b) => {
		const ids = sortedSeverities.map((x) => x.id);
		const aIndex = ids.indexOf(a.id);
		const bIndex = ids.indexOf(b.id);
		// check if indexes exist to handle sorting of engines with missing severities
		if (
			aIndex >= 0 &&
			bIndex >= 0 &&
			sortedSeverities[aIndex].severity !==
				sortedSeverities[bIndex].severity
		) {
			return aIndex - bIndex;
		} else {
			return sortByName(a, b);
		}
	});
};

const sortByName = (a: Engine, b: Engine): number => {
	if (a.name && b.name) {
		return a.name.localeCompare(b.name);
	} else {
		return 0;
	}
};

/**
 * @returns a group of engine tiles
 */
const EngineTiles = () => {
	const [sortCriterion, setSortCriterion] = useLocalStore(
		"sort-criterion",
		SortByType.Severity
	);
	const [sortedEngines, setSortedEngines] = useState<Engine[]>([]);

	const setSortByType = (value: string) =>
		setSortCriterion(value as SortByType);

	const { engines, apiStatus } = useGetAllEngines((data, apiStatus) => {
		if (apiStatus.isSuccess && data) {
			return { engines: data, apiStatus: apiStatus };
		} else {
			return { engines: [], apiStatus: apiStatus };
		}
	});

	const sortedSeverities: EngineSeverity[] =
		useAppSelector(getEngineSeverities);

	useDeepCompareEffect(() => {
		const copy = [...engines];
		if (sortCriterion === SortByType.Name) {
			if (copy && copy.length > 1) {
				copy.sort((a, b) => {
					return sortByName(a, b);
				});
			}
		} else {
			if (sortedSeverities && sortedSeverities.length > 1) {
				sortEnginesBySeverity(copy, sortedSeverities);
			}
		}
		if (!deepEqual(sortedEngines, copy)) {
			setSortedEngines(copy);
		}
	}, [engines, sortCriterion, sortedSeverities]);

	return (
		<>
			{loadComponent(
				apiStatus,
				engines.length !== sortedEngines.length
					? engines
					: sortedEngines,
				sortCriterion,
				setSortByType
			)}
		</>
	);
};

export default EngineTiles;
