export const calculateSeverity = (
	isConnected: boolean,
	stripeColour: string
): number => {
	if (!isConnected) {
		return 3;
	}
	if (stripeColour === "red") {
		return 3;
	}
	if (stripeColour === "orange") {
		return 2;
	}
	return 1;
};

