import { ApiStatus } from "./api.enhanced";
import { useGetUserQuery, useGetUserByEmailQuery, User } from "./api.generated";
import { useMemoSelector } from "./memoSelector";

/**
 * A wrapper around the current user API call
 *
 * @returns the current user information
 */
export const useGetCurrentUser = () => {
	const selector = useMemoSelector(
		(data: User | undefined, apiStatus: ApiStatus) => {
			if (apiStatus.isSuccess && data) {
				return {
					user: data,
					apiStatus,
				};
			}
			return {
				user: { issuer: "unknown", subject: "unknown" },
				apiStatus,
			};
		},
		"getCurrentUser"
	);
	return useGetUserQuery(undefined, {
		selectFromResult: (res) => selector(res.data, res),
	});
};

/**
 * A wrapper around the user by email API call
 *
 * @param email the email
 * @returns the user(s) that matches the given email
 */
export const useGetUserByEmail = (email: string): Array<User> => {
	const selector = useMemoSelector(
		(data: User[] | undefined, apiStatus: ApiStatus) => {
			if (apiStatus.isSuccess && data) {
				return {
					users: data,
				};
			}
			return { users: [] };
		},
		"getUserByEmail"
	);
	const { users } = useGetUserByEmailQuery(
		{ email },
		{
			selectFromResult: (res) => selector(res.data, res),
		}
	);
	return users;
};

export const GetDisplayValue = (user?: User) => {
	return user?.name ?? user?.email ?? "User";
};
